<template>
	<!-- <div id="wrap" :class="{login: $route.name == 'MEM901M01'}"> -->
	<div id="adm-wrap" :class="{login: $route.name == 'MEM901M01'}">
		<template v-if="menuStack.indexOf('ADMIN_LOGN') >= 0">
			<p style="color:white;">{{$store.state.profile.name}}</p>
			<router-view ><!-- 로그인콘텐츠 --></router-view>
		</template>
		<template v-else>
			<div class="container" :class="viewConfig.classNm">
				<template v-if="viewConfig.component">
					<component :is="viewConfig.component" v-bind="viewConfig.params" />
				</template>
				<template v-else>
					<div class="menu-wrap">
						<div class="menu">
							<a href="/admin"><img src="/images/admin/logo.png" alt="관리자로고"/></a>
							<span @click="move('HOME')" style="position: absolute;">{{$store.state.profile.name}}</span>
							<img class="logout" src="/images/admin/logout.png" alt="로그아웃" @click="$.logout('/admin')"/>
							<div class="scr_box">
								<template v-for="depth1 in $store.state.menu.ADMIN_MENU">
									<div class="menu_set" :key="depth1.menuId" v-if="depth1.menuDivCd != '99'">
										<div class="menu_title">
											<img class="menu_icon" :src="depth1.menuImgUrl||'/images/admin/menu_01.png'" alt="메뉴1"/>
											<p>{{depth1.menuNm}}</p>
											<img class="menu_up" :src="getMenuOnOff(depth1.menuId) ? '/images/admin/menu_down.png' : '/images/admin/menu_up.png'" alt="업버튼" @click="setMenuOnOff(depth1.menuId)"/>
										</div>
										<ul class="menu_list" v-if="getMenuOnOff(depth1.menuId)">
											<li v-for="depth2 in depth1.child" :key="depth2.menuId" :class="{click: depth2.menuId == $router.getMenu(menuStack[2]).menuId}">
												<a href="javascript:void(0)" @click="move(depth2)">{{depth2.menuNm}}</a>
												<!--span class="new">N</span-->
												<span style="display:none">{{depth2}}</span>
											</li>
										</ul>
									</div>
								</template>
							</div>
						</div>
						<div class="submenu" v-if="menuStack.length > 2">
							<div class="tit">{{$router.getMenu(menuStack[1]).menuNm}}</div>
							<div class="category">{{$router.getMenu(menuStack[2]).menuNm}}</div>
							<ul class="submenu_list">
								<li v-for="depth3 in $router.getMenu(menuStack[2]).child" :key="depth3.menuId" :class="{click: depth3.menuId == $router.getMenu(menuStack[3]).menuId}">
									<a href="javascript:void(0)" @click="move(depth3)">{{depth3.menuNm}}</a>
									<span class="show" v-if="depth3.menuId == $route.name">&#9654;</span>
								</li>
							</ul>
						</div>
					</div>
				</template>

				<router-view @setViewConfig="setViewConfig" @clearViewConfig="clearViewConfig" :key="$route.fullPath"/>
			</div>
		</template>

		<quick-menu-comp v-if="viewConfig.quickMenu !== false"/>
		
		<div class="popupWrap">
			<template v-for="(popup, index) in popupStack">
				<popup-wrap :key="popup.name" :name="popup.name" :path="popup.path" :param="popup.param" :closeBtn="popup.closeBtn" @updated="updatedPopup" @close_popup="closePopup" :style="{ 'z-index': 10000 + index * 100 }"/>
			</template>
			<template v-if="sessionTime.alert">
				<popup-wrap name="sessionTime" path="/common/sessionTime" :closeBtn="false" :param="sessionTime" @close_popup="closeSessionTime" :style="{ 'z-index': 90000 }"/>
				<!-- style="width: 509px; height: 448px; top: calc(50% - 190px); left: calc(50% - 220px)"-->
			</template>
			<template v-if="$store.state.loading">
				<popup-wrap name="loadingbar" path="/common/loading" :closeBtn="false" :style="{ 'z-index': 99999 }"/>
			</template>
		</div>
	</div>
</template>
<script>
import popupWrap from "@/components/common/PopupWrap";
import quickMenuComp from "@/components/QuickMenuComp.vue";

export default {
	name: "AdminApp",
	components: { popupWrap, quickMenuComp },
	data() {
		return {
			sessionTime : {
				expiredTime : this.$store.state.sessionTime.timeout,
				alert : false,
				closeToken : '',
			},
			closeMenu : [],
			viewConfig: {},
			popupStack: [],
		};
	},
	beforeMount() {
		window.addEventListener("beforeunload", event => {
			//console.log('beforeunload', this.$route)
			if(this.$route.meta.refreshDetectYn == 'Y'){
				event.preventDefault();
            	event.returnValue = "";
			}
        })
    },
	mounted() {
		this.$.loading(false);
		this.$.onEvent("popup", (path, param, opts, closeBtn, callback) => {
			this.openPopup(path, param, opts, closeBtn, callback);
		});

		var checkTime = new Date().getTime();
		var checkMinute = 10; //1분
		setInterval(()=>{
			var now = new Date().getTime();
			var timeout = this.$store.state.sessionTime.timeout;
			var token = this.$store.state.userToken.token;
			var token_start = this.$store.state.userToken.token_start;
			//console.debug('sessionTime', 'check', token);
			if(token){
				if(checkTime < now){
					checkTime = new Date().add(checkMinute, 'MINUTE');
					this.$.getNoti();
				}

				this.sessionTime.expiredTime = timeout - (now - token_start);
				//console.debug('sessionTime', 'check2', this.sessionTime.expiredTime);
				if(this.sessionTime.expiredTime <= 0){
					// 세션 남은시간이 0 보다 작을때 (로그아웃)
					this.sessionTime.alert = true;
					this.$.logout(false);
					//console.debug('sessionTime', 'logout', this.sessionTime.expiredTime, this.sessionTime.alert, this.sessionTime.closeToken != '');
				} else if(token == this.sessionTime.closeToken){
					// 로그아웃 안내 닫기버튼을 클릭했을 경우
					this.sessionTime.alert = false;
				} else if(this.sessionTime.expiredTime < this.$store.state.sessionTime.beforeAlert){
					// 세션 남은시간이 알럿시간보다 작을 경우
					this.sessionTime.alert = true;
					//console.debug('sessionTime', 'updated', this.sessionTime.expiredTime, this.sessionTime.alert, this.sessionTime.closeToken != '');
				} else {
					this.sessionTime.alert = false;
				}
			}
		}, 400);
	},
	errorCaptured() {
		//console.log('PopupWrap', '===== App =====', err, instance, info);
		return false;
	},
	methods: {
		setMenuOnOff(menuId){
			var idx = this.closeMenu.indexOf(menuId);
			if(idx < 0){
				this.closeMenu.push(menuId);
			} else {
				this.closeMenu.splice(idx, 1);
			}
		},
		getMenuOnOff(menuId){
			return this.closeMenu.indexOf(menuId) < 0;
		},
		setViewConfig(viewConfig){
			//console.debug('setViewConfig', this.$route.name, viewConfig);
			for(var key in viewConfig){
				this.$set(this.viewConfig, key, viewConfig[key]);
			}
		},
		clearViewConfig(){
			//console.debug('App', 'clearViewConfig', this.$route.name, viewConfig)
			this.viewConfig = {}
		},
		openPopup(path, param, opts, closeBtn, callback) {
			try {
				//console.log("openPopup", path, param, opts, closeBtn, callback);
				this.$.loading(true);

				opts = opts || {};
				var req = {
					name: "P" + new Date().getTime(),
					path : path,
					param: param||{},
					width: opts.width||400,
					height: opts.height||0,
					closeBtn: closeBtn !== false,
					callback : callback
				};
				this.popupStack.push(req);		
			} catch(err){
				callback(err);
			}
		},
		updatedPopup(popupVue) {
			var name = popupVue.name;
			//console.log("updatedPopup", name, popupVue);
			for (var i in this.popupStack) {
				if (this.popupStack[i].name == name) {
					var info = this.popupStack[i];
					//console.log("updatedPopup info", info, popupVue, this);
					if(info.height == 0){
						var height = popupVue.$el.clientHeight;
						var maxHeight = window.innerHeight - 40;
						info.height = (height < maxHeight) ? height : maxHeight;
					}
					this.$.loading(false);
					break;
				}
			}
		},
		closePopup(name, result) {
			//console.log("closePopup", name, result);
			for (var i in this.popupStack) {
				if (this.popupStack[i].name == name) {
					var info = this.popupStack[i];
					info.callback(result);
					this.popupStack.splice(i, 1);
					this.$.loading(false);
					break;
				}
			}
		},
		closeSessionTime(result){
			//console.log("sessionTime", "closeSessionTime", name, page)
			this.sessionTime.alert = false;
			if(result === false){
				this.$.logout('LOGIN');
			} else if(this.sessionTime.expiredTime <= 0){
				// 세션 남은시간이 0 보다 작을때 (로그아웃)
				this.$.logout('HOME');
			} else {
				this.sessionTime.closeToken = this.$store.state.userToken.token;
			}
		},
		login() {
			//this.$router.push('/MEM901M01');
			this.$router.move('LOGIN');
		},
		getComponent(path){
			return () => {
				return import(path);
			}
		},
		move(menu){
			switch(menu.menuId){
				case 'MAN002M01': return alert('서비스 준비중입니다.');
				default:
					this.$router.move(menu);
			}
		}
	}, 
	computed: {
		menuStack(){
			var stack = [];
			stack.push(this.$route.name);
			if(this.$route.meta){
				var menu = this.$route.meta;
				while(menu && menu.parentMenuId && menu.parentMenuId[0]){
					var parentId = menu.parentMenuId[0];
					stack.unshift(parentId);
					menu = this.$router.getMenu(parentId);
				}
			}
			//console.log('menuStack', stack, this.$router.getMenu(stack[3]))
			return stack;
		},
	}
};
</script>