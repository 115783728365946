<template v-if="list && list.length > 0">
  <component :is="type == 'ol' ? 'ol' : 'ul'">
    <li v-for="(item, index) in list" :key="get(item, code, index)" :class="get(item, clazzKey, clazz| {})">
		{{get(item, textKey)}}
		<list v-if="item[subKey]" :type="type" :code="code" :list="item[subKey]" :clazz="clazz" :subKey="subKey" :textKey="textKey" :clazzKey="clazzKey"/>
	</li>
  </component>
</template>

<script>
export default {
	name: "tree",
	props: ["type", "list", "code", "clazz", "subKey", "textKey", "clazzKey"],
	data() {
		//console.log("ListComp", 'data', this)
		return {}
	},
	methods: {
		get(item, key, defVal){
			var ret = (item && typeof item == 'object' && item[key]) || defVal;
			//console.log("ListComp", 'get', item, key, defVal, ret)
			return ret;
		}
	}
};
</script>
<style scoped>
	ul, ol { margin: 5px 0px 5px 10px; }
</style>