<template>
	<div class="error" v-if="error && showError">{{error.message}}</div>
</template>

<script>
/**
 * 에러 컴포넌트
 * 
 * 사용법)
 *      props   : 
 *      emit    :  
 *
 * 예시)
 *      <error-comp/>
 * 
 */
import PronError from '@/assets/js/PronError'
export default {
	name: 'ErrorComp',
	props: { 
		value: {},
		title:String,

		rules : {},
		ruleFunc: Object,
		showError : { type: Boolean, default : true },
	},
	data(){
		return {
		}
	},
	mounted() {
		
	},
	methods: {
		ruleCheck(val){
			var result = true;
			if(this.rules){
				switch(typeof this.rules){
					case 'object' :
						for(var key in this.rules){
							var obj  = this.rules[key];
							var rule = undefined;
							var args = undefined;
							var msg  = undefined;
							if(typeof key == 'string') rule = key;
							if(typeof obj == 'object'){
								if(obj.rule !== undefined) rule = obj.rule;
								if(obj.args !== undefined) args = obj.args;
								if(obj.msg  !== undefined) msg  = obj.msg;
							} else {
								args = this.rules[key];
							}
							
							result = this.check(val, rule, args, msg);
							if(result !== true) return result;
						}
						break;
					case 'string' :
						var rules = this.rules.split(',');
						for(var i in rules){
							var sp = rules[i].split(':');
							result = this.check(val, sp[0], sp.slice(1));
							if(result !== true) return result;
						}
						break;
					case 'function':
						result = this.check(val, this.rules);
						if(result !== true) return result;
				}
			}
			return result;
		},
		check(val, rule, args, msg){
			if(val instanceof Error) return false;
			var v = val;
			var title = '[' + (this.title ? this.title: v) + '] ' ;
			var result = true;
			if(typeof args == 'function'){
				result = args(v, msg)
			} else if(typeof rule == 'function'){
				result = rule(v, args, msg)
			} else if(['', undefined, null].indexOf(v) >= 0) {
				args = Array.isArray(args) ? args : [ args ];
				if(rule == 'required' && args[0] !== false && args[0] !== 'false') result = new PronError('IRREQD', msg || (title + '필수 입력사항 입니다.'));
			} else if(rule != 'required') {
				args = Array.isArray(args) ? args : [ args ];
				var baseRules = this.ruleFunc;
				if(baseRules[rule]) result = baseRules[rule](v, args, msg);
				else result = new PronError('IRNONE', title + '의 룰을 확인 할 수 없습니다.(' + rule + ')');
			}

			
			if(result !== true){
				var errCode = 'IRERR'
				/*if(result instanceof Error) console.debug('InputComp', 'ruleCheck', rule, v, result.code, result.message);
				else*/ if(typeof result == 'string') result = new PronError(errCode, result);
				else result = new PronError(errCode, title + '입력 룰 검증에 실패했습니다.');

				return result;
			}
			return true;
		},
	},
	computed: {
		error(){
			if(this.value instanceof Error)
				return this.value;
			else 
				return false;
		}
	}
}
</script>
<style scoped>
.error {
	font-size: 80%;
	color:red;
	position: absolute;
    left: 0px;
	width:200%;
	max-width: 1200px;
	margin-top: 2px;
}
</style>