<template>
	<component :is="tagNm || 'span'" class="selectDate">
		<div class="select_wrap" :class="classY">
			<select :value="year" @input="change('year', $event)" :disabled="disabled" ref="year">
				<option value="" disabled hidden selected>년도</option>
				<option v-show="disabled" :value="year">{{year}}</option>
				<template v-for="y in (toDate.getFullYear() - fromDate.getFullYear() + 1)">
					<option  :key="toDate.getFullYear() - y + 1" :value="toDate.getFullYear() - y + 1">{{ toDate.getFullYear() - y + 1 }}</option>
				</template>
			</select>
		</div>
		<template v-if="['YEAR'].indexOf(type.toUpperCase()) < 0">&nbsp;
			<div class="select_wrap" :class="classM">
				<select :value="month" @input="change('month', $event)" :disabled="disabled" ref="month">
					<option value="" disabled hidden selected>월</option>
					<option v-show="disabled" :value="month">{{month}}</option>
					<template v-for="m in 12">
						<option :key="m" :value="m" v-if="check('month', m)">{{ m }}</option>
					</template>
				</select>
			</div>
		</template>
		<template v-if="['YEAR', 'MONTH'].indexOf(type.toUpperCase()) < 0">&nbsp;
			<div class="select_wrap" :class="classD">
				<select :value="day" @input="change('day', $event)" :disabled="disabled" ref="day">
					<option value="" disabled hidden selected>일</option>
					<option v-show="disabled" :value="day">{{day}}</option>
					<template v-for="d in 31">
						<option :key="d" :value="d" v-if="check('date', d)">{{ d }}</option>
					</template>
				</select>
			</div>
		</template>
		<template v-if="['YEAR', 'MONTH', 'DATE'].indexOf(type.toUpperCase()) < 0">&nbsp;
			<div class="select_wrap" :class="classH">
				<select :value="hour" @input="change('hour', $event)" :disabled="disabled" ref="day">
					<option value="" disabled hidden selected>시</option>
					<option v-show="disabled" :value="hour">{{hour}}</option>
					<template v-for="h in 24">
						<option :key="h-1" :value="h-1" v-if="check('hour', h-1)">{{ h-1 }}</option>
					</template>
				</select>
			</div>
		</template>
		<template v-if="['YEAR', 'MONTH', 'DATE', 'HOUR'].indexOf(type.toUpperCase()) < 0">&nbsp;
			<div class="select_wrap" :class="classMi">
				<select :value="minute" @input="change('minute', $event)" :disabled="disabled" ref="day">
					<option value="" disabled hidden selected>분</option>
					<option v-show="disabled" :value="minute">{{minute}}</option>
					<template v-for="mi in 60">
						<option :key="mi - 1" :value="mi - 1" v-if="check('minute', mi - 1)">{{ mi - 1 }}</option>
					</template>
				</select>
			</div>
		</template>
		<slot name="after"></slot>
		<!--span class="error" v-if="errMsg && showError" :style="{top: ($refs.year.offsetHeight + $refs.year.offsetTop + 1) + 'px'}">{{errMsg}}</span-->
		<errorComp ref="error" :value="value" :title="title" :showError="showError"/>
	</component>
</template>

<script>
import formatter from '@/assets/js/formatter'
import PronError from '/src/assets/js/PronError'
/**
 * 날짜선택
 *      -> 날짜 선택 후 선택한 날짜를 emit 으로 전송
 * 
 * 사용법)
 * @props value	: v-model 객체
 * @props type	: 선택 타입 (date, month, year)
 * @props from  : 시작일
 * @props to    : 종료일
 * @props term  : 
 * 
 * @emit  input : File객체
 * 
 * @예시
 *  <file-select type="file" v-model="fileModel" :src="fileURL" :fileName="fileName" disabled="Y"/>
 *  <file-select type="image" v-model="imageModel" :src="imageURL" defaultSrc="/res/img/default.png"/>
 */
import errorComp from "@/components/ErrorComp.vue";
export default {
	components:{errorComp},
	props: {
		value	: { default: "" },
		type	: { type: String, default: "DATE" },
		title	: { type: String, default: "" },
		format	: { type: String, default: "" },
		from	: { default: "" },
		to		: { default: "" },
		disabled: {},
		
		showError : { type: Boolean, default : true },
		rules : { },
		tagNm : String,
		classY: {type:String, default:'year'},
		classM: {type:String, default:'lic'},
		classD: {type:String, default:'lic'},
		classH: {type:String, default:'lic'},
		classMi: {type:String, default:'lic'},
	},
	data() {
		return {
			year  : '',
			month : '',
			day   : '',
			hour  : '',
			minute: '',
			errMsg: '',
		};
	},
	watch: {
        value(){
			this.setDate();
		},
		rules(){
			var date = this.getDate();
			if(date instanceof Date){
				this.emitDate(date);
			}
		},
		fromDate(){
			this.setDate();//this.$nextTick(() => this.setDate());
			/*var date = this.getDate();
			if(date instanceof Date){
				this.emitDate(date);
			}*/
		},
		toDate(){
			this.setDate();//this.$nextTick(() => this.setDate());
			/*var date = this.getDate();
			if(date instanceof Date){
				this.emitDate(date);
			}*/
		},
	},
	mounted() {
		this.setDate();
	},
	methods : {
		setDate(){
			//console.log('SelectDate', 'setDate', this.title, this.value);
			if(!this.value) return;
			if(this.value instanceof Error) return;
			try{
				var date = formatter.date(this.value, 'date');
				if(this.disabled){
					this.$set(this, 'year'  , date.getFullYear());
					this.$set(this, 'month' , date.getMonth() + 1);
					this.$set(this, 'day'   , date.getDate());
					this.$set(this, 'hour'  , date.getHours());
					this.$set(this, 'minute', date.getMinutes());
				} else {
					this.emitDate(date);
				}
			} catch(e){
				this.emitError(e);
			}
		},
		getDate(){
			var type = this.type.toUpperCase() || 'DATE';
			if(!this.year ) return new PronError("IRERR", "년도를 선택해 주세요.");
			if(!this.month&& type != 'YEAR') return new PronError("IRERR", "월를 선택해 주세요.");
			if(!this.day  && type == 'DATE') return new PronError("IRERR", "날짜를 선택해 주세요.");
			return new Date(this.year, (this.month || 1) - 1, this.day || 1);
		},
		getFormat(date){
			switch(this.type.toUpperCase()){
				case "YEAR"  : return date.format('yyyy');
				case "MONTH" : return date.format('yyyyMM');
				case "HOUR"  : 
				case "MINUTE": return date.format('yyyyMMddHHmm');
				case "DATE"  : 
				default      : return date.format('yyyyMMdd');
			}
		},
		emitDate(date){
			//console.log('SelectDate', 'emitDate', this.title, date.format('yyyyMMdd'), this.fromDate.format('yyyyMMdd'), this.toDate.format('yyyyMMdd'));
			var result = "";
			if(date instanceof Date){
				if(date > this.toDate) date = this.toDate
				else if (date < this.fromDate) date = this.fromDate
				result = this.getFormat(date);
			} else if(date instanceof Error){
				this.emitError(date)
				return date;
			}

			//console.log('SelectDate', 'emitDate', this.title, result, this.value);
			if(this.validate(result)){
				this.$set(this, 'year' , date.getFullYear());
				this.$set(this, 'month', date.getMonth() + 1);
				this.$set(this, 'day'  , date.getDate());
				this.$set(this, 'hour'  , date.getHours());
				this.$set(this, 'minute', date.getMinutes());
				this.errMsg="";
				if(result != this.value) this.$emit('input', result);
			}
			return result;
		},
		emitError(e){
			if(e instanceof Error){
				if(this.value instanceof Error && this.value.message == e.message){
					//console.debug('SelectDate', 'emitError', e.message);
				} else {
					//console.log('SelectDate', 'emitError', this.title, e);
					this.errMsg = e.message;
					this.$emit('input', e);
				}
			}
			return e;
		},

		change(type, event){
			var val = event.target.value;
			this[type] = val;
			var date = this.getDate();
			if(date instanceof Date){
				//날짜가 바뀌면서 일이 해당 년월에 없어 다음월로 넘어 갈 경우 해당 년월의 마지막일로 세팅한다.
				if(date.getMonth() != this.month - 1) date.setDate(0);
			}
			this.emitDate(date);
		},
		check(type, val){
			var date = new Date(this.year, (this.month || 1) - 1, this.day||1, this.hour||0, this.minute||0);
			switch(type){
				case 'month':
					if(this.year){
						date.setMonth(val - 1);
						var ym = date.format('yyyyMM');
						return this.fromDate.format('yyyyMM') <= ym && ym <= this.toDate.format('yyyyMM');
					} else {
						return false;
					}
				case 'day':
					if(this.year && this.month){
						date.setDate(val);
						var ymd = date.format('yyyyMMdd');
						return date.getMonth() == (this.month-1) && this.fromDate.format('yyyyMMdd') <= ymd && ymd <= this.toDate.format('yyyyMMdd');
					} else {
						return false;
					}
			}

			return true;
		},
		
		validate(val){
			var result = true;

			if(this.rules){
				switch(typeof this.rules){
					case 'object' :
						for(var key in this.rules){
							var obj  = this.rules[key];
							var rule = undefined;
							var args = undefined;
							var msg  = undefined;
							if(typeof key == 'string') rule = key;
							if(typeof obj == 'object'){
								if(obj.rule !== undefined) rule = obj.rule;
								if(obj.args !== undefined) args = obj.args;
								if(obj.msg  !== undefined) msg  = obj.msg;
							} else {
								args = this.rules[key];
							}
							
							result = this.ruleCheck(val, rule, args, msg);
							if(result !== true) return false;
						}
						break;
					case 'string' :
						var rules = this.rules.split(',');
						for(var i in rules){
							var sp = rules[i].split(':');
							result = this.ruleCheck(val, sp[0], sp.slice(1));
							if(result !== true) return false;
						}
						break;
					case 'function':
						result = this.ruleCheck(val, this.rules);
						if(result !== true) return false;
				}
			}

			return result;
		},
		ruleCheck(val, rule, args, msg){
			if(val instanceof Error) return false;
			var title = '[' + (this.title ? this.title: val) + '] ' ;
			var result = true;
			if(typeof args == 'function'){
				result = args(val, msg)
			} else if(typeof rule == 'function'){
				result = rule(val, args, msg)
			} else if(val == '') {
				args = Array.isArray(args) ? args : [ args ];
				if(rule == 'required' && args[0] !== false && args[0] !== 'false') result = new PronError('IRREQD', msg || (title + '필수 입력사항 입니다.'));
			} else if(rule != 'required') {
				args = Array.isArray(args) ? args : [ args ];
				var baseRules = {}

				if(baseRules[rule]) result = baseRules[rule]();
				else result = new PronError('IRNONE', title + '의 룰을 확인 할 수 없습니다.(' + rule + ')');
			}
			
			if(result !== true){
				var errCode = 'IRERR'
				/*if(result instanceof Error) console.debug('selectDate', 'ruleCheck', rule, val, result.code, result.message);
				else*/ if(typeof result == 'string') result = new PronError(errCode, result);
				else result = new PronError(errCode, title + '입력 룰 검증에 실패했습니다.');
				
				this.emitError(result);
				return result;
			}
			return true;
		},
	},
	computed : {
		fromDate(){
			//console.log('SelectDate', 'fromDate', this.title, this.from);
			var date = new Date();
			try{
				//from, to 둘다 입력되지 않으면 -50년전부터 현재까지를 기간으로 한다.

				var from = this.from;
				if(typeof from !== 'string') from = "";
				if(!from && !this.to) {
					date.add(-50, 'year');
				} else {
					//from이 입력되지 않으면 현재날짜를 기본으로 한다.
					//from이 Y,M,D로 시작할 경우 현재날짜에서 년,월,일을 더해서 계산한다.
					//from이 yyyyMMdd로 입력될 경우 날짜를 파싱한다. 
					switch(from.toUpperCase().charAt(0)){
						case ""  :
							break;
						case "Y" :
							date.add(parseInt(from.substr(1)), 'year');
							break;
						case "M" :
							date.add(parseInt(from.substr(1)), 'month');
							break;
						case "D" :
							date.add(parseInt(from.substr(1)), 'day');
							break;
						default  : 
							date = formatter.date(from, 'date');
							break;
					}
				}
			} catch(e){ 
				//console.warn('SelectDate', 'from format is incorrect.', this.from, e)
				}
			// console.debug('SelectDate', 'fromDate', this.title, from, date.format('yyyyMMdd'));
			return date;
		},
		toDate(){
			//console.log('SelectDate', 'toDate', this.title, this.to);
			var date = new Date();
			try{
				//to가 입력되지 않으면 현재날짜를 기본으로 한다.
				//to가 Y,M,D로 시작할 경우 현재날짜에서 년,월,일을 더해서 계산한다.
				//to가 yyyyMMdd로 입력될 경우 날짜를 파싱한다.
				var to = this.to;
				if(typeof to !== 'string') to = "";
				switch(to.toUpperCase().charAt(0)){
					case "Y" : 
						date.add(parseInt(to.substr(1)), 'year');
						break;
					case "M" :
						date.add(parseInt(to.substr(1)), 'month');
						break;
					case "D" :
						date.add(parseInt(to.substr(1)), 'day');
						break;
					case "" : 
						break;
					default:
						date = formatter.date(to, 'date');
						break;
				}
			} catch(e){ 
				// console.warn('SelectDate', 'to format is incorrect.', this.to, e)
				}
			// console.debug('SelectDate', 'toDate', this.title, to, date.format('yyyyMMdd'));
			return date;
		}
	}
}
</script>
<style scoped>
.selectDate {
	position: relative;
	overflow: initial;
}
</style>