import Vue from 'vue'
import axios from 'axios'
import store from '/src/store'
import router from '/src/router'
import PronError from '/src/assets/js/PronError'

window.setLogConfig = function (debug, useTag) {
	store.commit('logConfig', { debug: debug, useTag: useTag })
}

window.nativeConsole = {
	debug : console.debug,
	log   : console.log,
	info  : console.info,
	warn  : console.warn,
	error : console.error
}

console.debug = function(){ commonConloseLog('debug', arguments); }
console.log   = function(){ commonConloseLog('log'  , arguments); }
console.info  = function(){ commonConloseLog('info' , arguments); }
console.warn  = function(){ commonConloseLog('warn' , arguments); }
console.error = function(){ commonConloseLog('error', arguments); }

function commonConloseLog(level, args){
	if(!window.nativeConsole ) return;
	if(!store.state || !store.state.logConfig) return;
	if(!store.state.logConfig.debug) return;
	if(['error', 'warn'].indexOf(level) < 0 && (store.state.logConfig.useTag && store.state.logConfig.useTag.indexOf(args[0]))) return;
	
	var arrArgs = Array.from(args);
	var time = new Date().format('[HH:mm:ss.SSS]');
	if(typeof arrArgs[0] != 'object'){
		arrArgs[0] = time + ' ' + arrArgs[0]
	} else {
		arrArgs.unshift(time);
	}

	var stack = new Error().stack;
	if (stack) {
		arrArgs.push('\n▶ ' + stack.split('\n')[3].trim());
	}

	try{
		window.nativeConsole[level].apply(common, Array.prototype.slice.call(arrArgs));
	} catch(e){
		arrArgs = null;
	}
}

var evetnBus = new Vue();

var common = new class {
	API_REFRESH = '/api/mem/refreshToken';

	install(Vue) {
		Vue.prototype.$ = this;
		Vue.prototype.$axios = axios;
		Vue.prototype.$axios.defaults.headers.common['_call'] = 'ajax';
		Vue.prototype.$axios.defaults.headers.common['Content-Type'] = 'application/json';
		Vue.prototype.$axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

		Vue.filter("number", val => {
			return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		});
	}

	emitEvent(){
		// console.log('emitEvent', evt, arguments);
		evetnBus.$emit.apply(evetnBus, arguments);
	}

	onEvent(evt, func){
		// console.log('onEvent', evt);
		evetnBus.$on(evt, func);	
	}

	popup(path, param, opts, closeBtn) {
		// console.log("App.$popup", this, param);
		return new Promise((resolve, reject) => {
			common.emitEvent("popup", path, param, opts, closeBtn, (result) => {
				if(result instanceof Error){
					reject(result);
				} else {
					resolve(result);
				}
			});
		});
	}

	// move(pageId, param) {
	// 	if (!pageId) {
	// 		console.log("router move : name is none.");
	// 		return false;
	// 	} else if (router.history.current.name == pageId) {
	// 		console.log("router move : NavigationDuplicated: Avoided redundant navigation to current location", router.history.current.name);
	// 		return false;
	// 	} else {
	// 		console.log("router move", pageId);
	// 		return router.push({ name: pageId, param: param });
	// 	}
	// }

	loading(arg){
		store.commit('loading', arg);
	}

	focus(el){
		if(typeof el.scrollIntoView == 'function'){
			//console.log('focus scrollIntoView', el);
			el.scrollIntoView({behavior : 'smooth'});
			return true;
		} else if(typeof el.focus == 'function'){
			if(!el.attributes.tabindex){
				//console.log('focus setAttribute', el);
				el.setAttribute('tabindex', 0);
			}
			el.focus();
			return true;
		} else if(el.$el){
			common.focus(el.$el);
		} else if(Array.isArray(el) && el.length > 0){
			common.focus(el[0]);
		}
		return false;
	}

	validate(data){
		var result = true;
		switch(typeof data){
			case 'object':
				if(data instanceof Error){
					if(data.details instanceof Element){
						common.focus(data.details)
					}
					alert(data.message);
					return data;
				}
				else if(data == null)
					return result;
				
				for(var i in data){
					result = common.validate(data[i]);
					if(result !== true) return result;
				}
				break;
			//default :
			//	return result;
		}
		return result;
	}

	login(id, password, keepLoginYn){
		return new Promise((resolve, reject) => {
			common.httpPost("/api/mem/login", {id, password})
				.then((res1) => {
					if(res1.data.error && res1.data.error_description && res1.data.error_description != "") {
						alert(res1.data.error_description);
						return;
					}
					

					// 로그인 성공
					// store에 토큰 저장 후 회원정보 조회
					store.commit("userToken", res1.data);
					common.httpPost("/api/mem/getUserInfo")
						.then((res2) => {
							if(res2.data){
								res2.data.keepLoginYn = keepLoginYn;
								store.commit("userInfo", res2.data);
								resolve(res2)
							} else {
								store.commit("userToken");							
							}
						}).catch(reject);
				}).catch(err => {
					store.commit("userToken");
					reject(err);
				});
		});
	}

	logout(url) {
		store.commit('userToken');
		store.commit('userInfo');
		clearInterval(common.loginTimer);
		if (url !== false) {
			router.move(url || 'HOME', false);
		}
	}

	getNoti(){
		return new Promise((resolve, reject) => {
			var url = '/api/mem/getNotiCount';
			var userInfo = store.state.userInfo;
			var http = null;
			// console.debug('getNoti', userInfo.mberSeq, userInfo.keepLoginYn);
			if(userInfo.mberSeq){
				if(userInfo.keepLoginYn == 'Y'){
					http = common.httpPost(url, false, false, false);
				} else {
					var token = store.state.userToken.token;
					var token_expire = store.state.userToken.token_expire;
					var now = new Date().getTime();
					if (!token || (token_expire && now < token_expire)){
						var method = 'post';
						var headers = { Authorization : 'Bearer ' + token};
						http = axios({url, method, headers});
					} else {
						return;
					}
				}
				http.then(res => {
						// console.debug('getNoti result', res);
						if(res.data && res.data.mberSeq && res.data.mberSeq == userInfo.mberSeq){
							resolve(res);
							store.commit('notiNewCnt', res.data.noti.notiNewCnt);
						}
					}).catch(reject);
	
			}
		})
	}

	refreshToken(){
		return new Promise((resolve, reject) => {
			//console.debug('http ' + url + ' :: refreshToken');
			store.commit('userToken', {});
			axios.post(common.API_REFRESH)
				.then(res => {
					// console.debug('common.http:: refreshToken success', res);
					store.commit('userToken', res.data);
					resolve(res);
				}).catch(err => {
					// console.debug('common.http:: refreshToken error', err.response, err);
					reject(err);
				});
		});
	}

	http(url, data, options, method, loadingYn) {
		if(loadingYn !== false) common.loading(true);
		// console.log('common.http', url, method, data, options);
		return new Promise((resolve, reject) => {
			if(url.indexOf(common.API_REFRESH) >= 0){
				//refresh 요청
				common.refreshToken().then(res => {
					resolve(res);
					if(loadingYn !== false) common.loading(false);
				}).catch(err => {
					reject(err);
					if(loadingYn !== false) common.loading(false);
				})
			} else if(common.validate(data) === true){
				callAPI(resolve, reject, true);
			} else {
				common.loading(false);
				return;
			}
		});

		function callAPI(resolve, reject, refreshYn){
			var token = store.state.userToken.token;
			var token_expire = store.state.userToken.token_expire;
			var now = new Date().getTime();
			if (!token || (token_expire && now < token_expire)) {
				// token 없음 || 토큰유효시간이 지나지 않았을 경우
				options = options || {};
				options.headers = options.headers || {};
				options.method = method || 'post';
				options.url = url;
				if(options.method.toUpperCase() == 'GET'){
					options.params = data;
				} else {
					options.data = data;
				}
				
				// token 이 있을 경우 header 에 Authorization 을 설정한다.
				if (token && url.indexOf(common.API_REFRESH) < 0) options.headers.Authorization = 'Bearer ' + token;
				// console.log('common.http', url, ':: send', method, data, options);
				axios(options)
					.then(res => {
						// console.log('common.http', url, ':: success', res, res.data);
						try { resolve(res); } 
						finally { common.loading(false); }
					})
					.catch(err => {
						// console.error('common.http', url, ':: error', err, err.response);
						var res = err.response;
						if (res && res.data && (res.data.error == 'invalid_token' || res.data.error == 'unauthorized' || res.data.error == 'access_denied') && url.indexOf(common.API_REFRESH) < 0) {
							// http 상태코드 401 && 'invalid_token' 에러 && refresh 요청이 아닐 경우
							var token = store.state.userToken.token;
							if (token) {
								// 로그인 세션이 만료된 경우
								if(refreshYn){
									common.refreshToken()
										.then(() => { callAPI(resolve, reject, false); })
										.catch(() => { 
											alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
											common.logout('LOGIN');
											common.loading(false);
										 });
								} else if (res.data.error == 'unauthorized' || res.data.error == 'access_denied'){
									alert('권한이 없습니다. 메인으로 이동합니다.');
									router.move('HOME');
									common.loading(false);
								} else {
									try{ reject(err); } finally { common.loading(false);}
								}
							} else {
								// 로그인이 필요한 페이지를 비로그인 상태에서 접근한 경우
								alert('로그인 후 이용해 주세요.');
								router.move('LOGIN');
								common.loading(false);
							}
						} else {
							// 기타 에러
							try{ reject(err); }
							finally { common.loading(false);}
						}
					});
			} else {
				// 토큰유효시간이 지났을 경우
				common.refreshToken()
					.then(() => { callAPI(resolve, reject, false); })
					.catch(() => { 
						alert('로그인 세션이 만료되었습니다. 로그인 페이지로 이동합니다.');
						common.logout('LOGIN');
						common.loading(false);
					 });
			}
		}
	}
	httpPost(url, data, options, loadingYn) {
		return common.http(url, data, options, 'post', loadingYn);
	}
	httpGet(url, data, options, loadingYn) {
		return common.http(url, data, options, 'get', loadingYn);
	}
	httpErrorCommon(err, defMsg){
		// console.log('common.http', 'httpErrorCommon', err.response, defMsg);
		var msg = false;
		var status = false;
		if(err){
			// console.debug('httpErrorCommon', err.response, defMsg)
			if(err instanceof Error){
				alert(err.message);
				if(err.details instanceof Element) common.focus(err.details)
				return;
			} else if(err.response && err.response.data){
				status = err.response.status || status;
				var data = err.response.data;
				if(data.error_description){
					return alert(err.response.data.error_description);
				} else {
					if(data.message){
						if(data.message.indexOf('FileSizeLimitExceededException') >= 0){
							msg = '업로드 용량이 제한되었습니다.';
							var bytes = data.message.match(/(?<=size of\s+).*?(?=\s+bytes)/gs);
							if(bytes) msg += '[' + bytes + 'bytes]'
							return alert(msg);
						}
					}
					msg = data.error || false;
					status = data.status || status;
				}
			}
		} else {
			// console.debug('httpErrorCommon', err, defMsg)
		}

		status = status ? (' [' + status + ']') : '';
		if(defMsg){
			alert(defMsg + status);
		} else if(defMsg !== false){
			alert((msg || '통신 중 오류가 발생했습니다.') + status);
		}
	}

	fileUpload(fileType, files, url, data){
		return new Promise((resolve, reject) => {
			var formData = new FormData();
			
			//data validate 체크
			if(data){
				if(common.validate(data) === true){
					for(var key in data){
						formData.append(key, typeof data[key] == 'object' ? JSON.stringify(data[key]) : data[key]);
					}
				} else {
					common.loading(false);
					return;
				}
			}

			//파일 체크
			var fileArr = Array.isArray(files) ? files : [files];
			for(var idx in fileArr){
				//파일 타입 체크
				var file = fileArr[idx];
				if(file instanceof File){
					var types = file.type.split('/');
					// console.log('common.fileUpload', 'type', types);
					fileType = fileType || '';
					switch(fileType.toUpperCase()){
						case 'ALL' : break;
						case 'IMG' : 
							if(types[0] != 'image'){
								return reject(new PronError('FFF', '이미지만 업로드가 가능합니다.'));
							}
							break;
						case 'PDF' : 
							if(!(types[0] == 'application' && types[1] == 'pdf')){
								return reject(new PronError('FFF', 'PDF만 업로드가 가능합니다.'));
							}
							break;
						case 'IMG_PDF_DOC' :
							if(types[0] != 'image' 
									&& !(types[0] == 'application' && types[1] == 'pdf')
									&& !(types[0] == 'application' && types[1] == 'haansofthwp')		// hwp
									&& !(types[0] == 'application' && types[1] == 'vnd.ms-excel')		// xls
									&& !(types[0] == 'application' && types[1] == 'vnd.openxmlformats-officedocument.spreadsheetml.sheet')			// xlsx
									&& !(types[0] == 'application' && types[1] == 'msword')				// doc
									&& !(types[0] == 'application' && types[1] == 'vnd.openxmlformats-officedocument.wordprocessingml.document')	// docx
									&& !(types[0] == 'application' && types[1] == 'vnd.ms-powerpoint')	// ppt
									&& !(types[0] == 'application' && types[1] == 'vnd.openxmlformats-officedocument.presentationml.presentation')	// pptx
							){
								return reject(new PronError('FFF', '이미지, PDF , 문서 파일만 업로드가 가능합니다.'));
							}	
							break;
						case ''	:
							if(types[0] != 'image' && !(types[0] == 'application' && types[1] == 'pdf')){
								return reject(new PronError('FFF', '이미지와 PDF만 업로드가 가능합니다.'));
							}
							break;
						default		: 
					}
					// console.log('common.fileUpload', 'add File', file);
					formData.append('file', file);
				} else if(!file){
					// console.log('common.fileUpload', 'file is empty', idx);
					continue;
				} else {
					return reject(new PronError('FFF', '업로드할 파일을 확인해주세요.'));
				}
			}
			formData.append('fileType', fileType);
			
			//파일 업로드
			// console.debug("[pnFileUpload] : " + url);
			common.httpPost(url, formData)
				.then(res => {
					// console.log('common.fileUpload', 'success', res);
					resolve(res);
				})
				.catch(err => {
					// console.log('common.fileUpload', 'error', err);
					reject(err);
				});
		});
	}

	fileDownload(url, data, options, method, errMsg){
		return new Promise((resolve, reject) => {
			if(typeof url != 'string' || url.length == 0){
				return reject(new PronError('FFF', '다운로드 URL을 확인해 주세요.'));
			}
			// console.debug("common.fileDownload", url);
			options = options || {};
			options.responseType = options.responseType || 'blob';
			method = method || (url.indexOf('/api/') == 0? 'POST' : 'GET');
			common.http(url, data, options, method)
				.then(res => {
					// console.log('common.fileDownload', 'success', res);
					if(res.data.size == 0){
						reject(res);
					} else {
						// BLOB 객체를 만들어서 파일 다운로드
						var blobUrl = window.URL.createObjectURL(res.data);
						var link = document.createElement('a');
						link.href = blobUrl;
						var fileName = res.headers.filename;
						if(fileName){
							// 헤더에 filename 이 있다면 해당 파일명으로 한다.
							link.setAttribute('download', decodeURIComponent(fileName));
						} else {
							// 헤더에 filename 이 없다면 URL 마지막 path를 파일명으로 한다.
							link.setAttribute('download', url.split("?")[0].substring(url.lastIndexOf("/") + 1));
						}
						document.body.appendChild(link);
						link.click();
						resolve(res);
					}
				})
				.catch(err => {
					// console.log('common.fileDownload', 'error', err, err.response);
					if(err && err.response && err.response.data instanceof Blob){
						var reader = new FileReader();
						reader.onload = function() {
							var response = {};
							try {
								response = JSON.parse(reader.result)
								err.response.data = response;
								if(errMsg){
									if(errMsg === true) errMsg = '파일 다운로드에 실패했습니다.';
									alert(err.response.data.error_description || errMsg);
								}
								// console.log('common.fileDownload', 'error', 'blob read success', response);
							} catch(er){
								// console.log('common.fileDownload', 'error', 'blob read error', er);
							}
							reject(err);
						}
						reader.readAsText(err.response.data);
					} else {
						reject(err);
					}
				});
		});
	}

	// KCP 본인인증
	kcpCert(this_obj, callback) {
		
		var veriUpHash = '';

		window.setKcpVeriUpHash = function(value) {
			veriUpHash = value;
		}
		
		window.setKcpcert = function(data) {
			var json_obj = eval('(' + data  + ')');
			
			if(veriUpHash != json_obj.up_hash) {
				alert('인증정보에 변조 위험이 감지되었습니다.');
			} else {
				callback(this_obj, json_obj);
			}
		}

		window.open('/MEM004P01', 'auth_popup', 'width=480,height=600,status=no,menubar=no,toolbar=no,resizable=no');
	}

	// 도로명 주소검색
	jusoSearch(this_obj, callback) {

		window.jusoCallback = function(data) {
			var json_obj = eval('(' + data  + ')');
			callback(this_obj, json_obj);
		}

		window.open("/MEM004P02","juso_popup","width=570,height=420, scrollbars=yes, resizable=yes");
	}

}

Date.prototype.add = function (value, type) {
	if(value == 0) return;
	var time = this.getTime();
	switch ((type||'DAY').toUpperCase()) {
		case 'YEAR':
			this.setFullYear(this.getFullYear() + value);
			break;
		case 'MONTH':
			var m = this.getMonth() + value;
			if (m >= 12) {
				this.add(Math.floor(m / 12), 'year');
				this.setMonth(m % 12);
			} else if (m < 0) {
				this.add(Math.floor(m / 12), 'year');
				this.setMonth((m % 12) + 12);
			} else {
				this.setMonth(m);
			}
			break;
		case 'DAY':
			this.setTime(time + (value * 86400000));  //24 * 60 * 60 * 1000
			break;
		case 'HOUR':
			this.setTime(time + (value * 3600000));   //60 * 60 * 1000
			break;
		case 'MINUTE':
			this.setTime(time + (value * 60000));     //60 * 1000
			break;
		case 'SECOND':
			this.setTime(time + (value * 1000));      //1000
			break;
	}
	return this;
}

export default common;