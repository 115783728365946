<template>
	<component :is="tagName || 'span'" @focusout="focusout" class="inputComp">
		<template v-if="type == 'textarea'">
			<textarea ref="input" :value="bindValue" @input="input" @focus="focus" @keydown="$emit('keydown', $event)" @keyup="$emit('keyup', $event)" :placeholder="placeholder||title" :class="classNm" :disabled="disabled" :readonly="readonly" :maxlength="maxLen"/>
		</template>
		<template v-else>
			<input ref="input" :type="bindType" :value="bindValue" @input="input" @focus="focus" @keydown="$emit('keydown', $event)" @keyup="$emit('keyup', $event)" :placeholder="placeholder||title" :class="classNm" :disabled="disabled" :readonly="readonly" :maxlength="maxLen"/>
			<!--span v-if="type == 'date'">
				<input type="image" src="/images/mem/icon_search.png" class="icon_search" @click="$refs.calendar.open()"/>
				<CalendarComp ref="calendar" v-model="val" :start="start" :end="end" :layer="true" @input="input()"/>
			</span-->
		</template>
		<errorComp ref="error" :value="value" :title="title" :showError="showError"/>
		<!--errorComp ref="error" :value="value" :title="title" :showError="showError" :rules="rules" :ruleFunc="ruleFunc"/-->
	</component>
</template>

<script>
/**
 * Input 필드
 * 
 * 사용법)
 *	  props   : 
 *				  type  : format 적용을 위한 type
 *				  value : 입력값. v-model 로 모델 바인딩하여 사용
 *				  title : input title
 *				  format : format 적용 시 사용
 *	  emit	:  
 *				  input : input 값 변경이 일어날 때 작동 (v-model 로 모델 바인딩 시 양방향 데이터바인딩)
 * 
 * 예시)
 *	  <InputComp type="number" v-model="format.number" title="숫자"/>
 * 
 */
import formatter from '@/assets/js/formatter'
import PronError from '@/assets/js/PronError'
import errorComp from "@/components/ErrorComp.vue";

export default {
	components: { errorComp },
	props: {
		type	: { type   : String, default: 'text' },
		value	: { },
		title	: { type	: String },
		format	: { type	: String },
		classNm	: { type : String },
		readonly	: { },
		disabled	: { },
		placeholder : { type : String },
		tagName	: String,

		maxlength : { },
		inputable: { },
		showError : { type: Boolean, default : true },
		rules : { },
		maxByte : { },
	},
	data() {
		//console.debug('inputComp', 'data', this.value);
		var inType	 = typeof this.value;
		if(inType == 'object'){
			if(this.value instanceof Date) inType = 'date';
			else inType = 'string'
		} else if(inType == 'undefined'){
			inType = 'string'
		}
		return {
			val : '',
			focusIn : false,
			inType : inType,
			ruleFunc:{
				maxLength	: (v, args, msg) => v.length <= args[0] || new PronError('IRMAXL', msg || ('['+this.title+'] 입력의 최대 길이를 확인해주세요. (' + args[0] + ')')),
				minLength	: (v, args, msg) => v.length >= args[0] || new PronError('IRMINL', msg || ('['+this.title+'] 입력의 최소 길이를 확인해주세요. (' + args[0] + ')')),
				length		: (v, args, msg) => v.length == args[0] || new PronError('IRLENG', msg || ('['+this.title+'] 입력 길이를 확인해주세요. (' + args[0] + ')')),
				
			}
		};
	},
	watch: {
        value(){
			//console.log('inputComp', 'watch value', this.title);
			if(this.value instanceof Error){
				// alert(this.value.message);
				//console.debug('InputComp', 'watch', this.value.message);
				/*if(!this.focusIn){
					this.$refs.input.focus();
				}*/
			} else {
				try{
					this.val = this.doFormat(this.value, 'string');
					this.validate();
				} catch (e){
					this.emitInput(e);
				}
			}
		},
		rules(){
			//console.log('inputComp', 'watch rules', this.title);
			if(this.validate()){
				this.emitInput(this.val);
			}
		}
	},
	mounted() {
		if(this.value instanceof Error){
			if(!this.focusIn){
				//alert(this.value);
				this.$refs.input.focus();
			}
		} else if(this.value){
			try{
				this.val = this.doFormat(this.value, 'string');
				this.validate();
			} catch (e){
				this.val = this.value;
				this.emitInput(e);
			}
		} else {
			this.validate();
		}
	},
	methods : {
		validate(){
			//console.log('inputComp', 'validate', this.val, this.rules);
			var result = true;
			
			//포맷팅 체크
			try {
				this.doFormat(this.val, this.format);
				//result = this.$refs.error.ruleCheck(this.val);
				
				if(this.rules){
					switch(typeof this.rules){
						case 'object' :
							for(var key in this.rules){
								var obj  = this.rules[key];
								var rule = undefined;
								var args = undefined;
								var msg  = undefined;
								if(typeof key == 'string') rule = key;
								if(typeof obj == 'object'){
									if(obj.rule !== undefined) rule = obj.rule;
									if(obj.args !== undefined) args = obj.args;
									if(obj.msg  !== undefined) msg  = obj.msg;
								} else {
									args = this.rules[key];
								}
								
								result = this.ruleCheck(rule, args, msg);
								if(result !== true) return false;
							}
							break;
						case 'string' :
							var rules = this.rules.split(',');
							for(var i in rules){
								var sp = rules[i].split(':');
								result = this.ruleCheck(sp[0], sp.slice(1));
								if(result !== true) return false;
							}
							break;
						case 'function':
							result = this.ruleCheck(this.rules);
							if(result !== true) return false;
					}
				}
			} catch(e){
				result = e;
			}

			if(result === true){
				return true;
			} else {
				if(result instanceof Error){
					this.emitInput(result);
				} else {
					this.emitInput(new PronError("FFF", result, this.$refs.input));
				}
				//console.log('inputComp', 'validate emit', result);
				return false;
			}
		},
		focus(evt){
			this.$emit('focus', evt);
			//console.log('inputComp', 'focus', event);
			this.focusIn = true;
		},
		focusout(){
			this.focusIn = false;
		},
		emitInput(val){
			try{
				//console.debug('inputComp', 'emit', val);
				if(val instanceof Error){
					if(this.value instanceof Error && this.value.message == val.message){
						//console.debug('inputComp', 'emitInput', val.message);
					} else {
						//console.warn('inputComp', 'emit error', this.title, val);
						if(!val.details) val.details = this.$refs.input;
						this.$emit('input', val);
					}
				} else {
					if(this.maxByte) {
						var strByte = 0;
						var limitStr = '';

						for(var i = 0; i < val.length; i++) {
							var strChar = val.charAt(i);

							if(escape(strChar).length >= 4) {
								strByte += 3;
							}else if(escape(strChar) == "%A7") {
								strByte += 3;
							}else {
								if(escape(strChar) != "%0D") strByte++;
							}

							if(strByte <= this.maxByte) {
								limitStr += strChar;
							}else {
								val = limitStr;
								this.val = limitStr;
								break;
							}
						}
					}

					var ret = this.doFormat(val, this.inType);
					if(this.value != ret){
						var format = this.doFormat(ret, 'string')
						if(format != this.val){
							if(this.type == 'url' && val.endsWith(location.host + format)) {
								//console.debug('InputComp', 'url format : ' + val, format);
							} else {
								throw new PronError('FFF', '입력형식을 확인해주세요 (' + val + ')', this.$refs.input);
							}
						}
						var check = this.validate();
						if(check instanceof Error) throw check;
						this.$emit('input', ret);
					}
				}
			} catch(e) {
				//console.warn('inputComp', 'emit error', this.title, e);
				if(this.value instanceof Error && this.value.message == e.message){
					//console.debug('inputComp', 'emitInput', e.message);
				} else {
					if(!e.details) e.details = this.$refs.input;
					this.$emit('input', e);
				}
			}
		},

		input(event) {
			//console.debug('inputComp', 'input', event);
			var val = event ? event.target.value : this.val;
			if(this.inputable){
				var reg = '';
				var regMap = { num: '0-9', eng:'a-zA-Z', uppr: 'A-Z', lowr:'a-z', han:'ㄱ-ㅎ가-힣'};
				if(Array.isArray(this.inputable)){
					for(var i in this.inputable){
						if(regMap[this.inputable[i]]){
							reg += regMap[this.inputable[i]];
						} else {
							reg += this.inputable[i];
						}
					}
				} else {
					reg = this.inputable;
				}
				//console.debug('inputComp', 'RegExp', val, reg, val.replace(new RegExp('[^' + reg + '.]', 'g'), ''));
				this.val = val.replace(new RegExp('[^' + reg + '.]', 'g'), '')
				if(this.val != val){
					this.$forceUpdate();
				}
			} else {
				this.val = val;
			}
			
			this.emitInput(this.val);
			this.$emit('changed', event, this.val);
		},
		doFormat(val, format){
			//console.log('inputComp', 'doFormat', format, val);
			var ret = val;
			var func = formatter[this.type];
			if(typeof func == 'function'){
				ret = func(val, format);
			}
			//console.log('inputComp', 'doFormat ret', format, val, ret);
			return ret;
		},
		
		ruleCheck(rule, args, msg){
			if(this.val instanceof Error) return false;
			var v = this.val || '';
			var title = '[' + (this.title ? this.title: v) + '] ' ;
			var result = true;
			if(typeof args == 'function'){
				result = args(v, msg)
			} else if(typeof rule == 'function'){
				result = rule(v, args, msg)
			} else if(v == '') {
				args = Array.isArray(args) ? args : [ args ];
				if(rule == 'required' && args[0] !== false && args[0] !== 'false') result = new PronError('IRREQD', msg || (title + '필수 입력사항 입니다.'));
			} else if(rule != 'required') {
				args = Array.isArray(args) ? args : [ args ];
				var baseRules = {
					maxLength	: () => v.length <= args[0] || new PronError('IRMAXL', msg || (title + '입력의 최대 길이를 확인해주세요. (' + args[0] + ')')),
					minLength	: () => v.length >= args[0] || new PronError('IRMINL', msg || (title + '입력의 최소 길이를 확인해주세요. (' + args[0] + ')')),
					length		: () => v.length == args[0] || new PronError('IRLENG', msg || (title + '입력 길이를 확인해주세요. (' + args[0] + ')')),
				}

				if(baseRules[rule]) result = baseRules[rule]();
				else result = new PronError('IRNONE', title + '의 룰을 확인 할 수 없습니다.(' + rule + ')');
			}

			
			if(result !== true){
				var errCode = 'IRERR'
				/*if(result instanceof Error) console.debug('InputComp', 'ruleCheck', rule, v, result.code, result.message);
				else*/ if(typeof result == 'string') result = new PronError(errCode, result);
				else result = new PronError(errCode, title + '입력 룰 검증에 실패했습니다.');

				this.emitInput(result);
				return result;
			}
			return true;
		},
	},

	computed : {
		bindType(){
			switch(this.type){
				case "password": return "password";
				default: return "text";
			}
		},
		bindValue(){
			//console.debug('bindValue', 'bindValue', this.value, this.val, this.focusIn, this.readonly, this.disabled);
			try {
				if( this.value instanceof Error || !this.value){
					return this.val;
				} else if(this.focusIn && !this.readonly && this.readonly !== '' && !this.disabled && this.disabled !== ''){
					return this.val;//this.doFormat(this.value, 'string');
				} else {
					return this.doFormat(this.value, this.format);
				}
			} catch(e) {
				return this.val;
			}
		},
		maxLen(){
			if(this.maxlength)
				return this.maxlength;
			switch(this.type){
				case 'date'		: return 8;
				case 'month'	: return 6;
				case 'year'		: return 4;
				case 'bizNo'	: return 10;
				case 'cell'		: return 11;
				case 'tell'		: return 11;
				case 'phone'	: return 11;
				default : return undefined;
			}
		},
		error(){
			if(this.value instanceof Error)
				return this.value;
			else 
				return false;
		}
	},
}
</script>
<style scoped>
.inputComp {
	position: relative;
}
</style>