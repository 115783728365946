import PronError from '/src/assets/js/PronError'

var SEP_YMD = '[-/.]?';
var SEP_YMDTIME = '[\\s]?';
var SEP_TIME = '[:]?';
var FORMAT_DATE = 'yyyyMMdd';
var FORMAT_YEAR   = 'yyyy';
var FORMAT_MONTH  = 'MM';
var FORMAT_DAY    = 'dd';
var FORMAT_HOUR   = 'HH';
var FORMAT_MINUTE = 'mm';
var FORMAT_SECOND = 'ss';

Date.prototype.format = function (f) {
    if (!this.valueOf()) return "";
    f = f || 'yyyyMMdd'
    var weekKorName = ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"];
    var weekKorShortName = ["일", "월", "화", "수", "목", "금", "토"];
    var weekEngName = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    var weekEngShortName = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    var d = this;
	var h = d.getHours() % 12;
    return f.replace(/(yyyy|yy|MM|dd|KS|KL|ES|EL|HH|hh|mm|ss|SSS|a\/p)/g, function ($1) {
        switch ($1) {
            case "yyyy": return d.getFullYear(); // 년 (4자리)
            case "yy": return (d.getFullYear() % 1000).padStart(2, '0'); // 년 (2자리)
            case "MM": return (d.getMonth() + 1).padStart(2, '0'); // 월 (2자리)
            case "dd": return d.getDate().padStart(2, '0'); // 일 (2자리)
            case "KS": return weekKorShortName[d.getDay()]; // 요일 (짧은 한글)
            case "KL": return weekKorName[d.getDay()]; // 요일 (긴 한글)
            case "ES": return weekEngShortName[d.getDay()]; // 요일 (짧은 영어)
            case "EL": return weekEngName[d.getDay()]; // 요일 (긴 영어)
            case "HH": return d.getHours().padStart(2, '0'); // 시간 (24시간 기준, 2자리)
            case "hh": return (h ? h : 12).padStart(2, '0'); // 시간 (12시간 기준, 2자리)
            case "mm": return d.getMinutes().padStart(2, '0'); // 분 (2자리)
            case "ss": return d.getSeconds().padStart(2, '0'); // 초 (2자리)
            case "SSS": return d.getMilliseconds(); // 밀리초
            case "a/p": return d.getHours() < 12 ? "오전" : "오후"; // 오전/오후 구분
            default: return $1;
        }
    });
}
String.prototype.string = function (len) { var s = '', i = 0; while (i++ < len) { s += this; } return s; };
if(typeof String.prototype.padStart != 'function'){
	String.prototype.padStart = function (len, pad) { return (pad||" ").string(len - this.length) + this; };
	String.prototype.padEnd   = function (len, pad) { return this + (pad||" ").string(len - this.length); };
}
Number.prototype.padStart = function (len, pad) { return this.toString().padStart(len, pad); };
Number.prototype.padEnd   = function (len, pad) { return this.toString().padEnd(len, pad); };

function initFilter(func){
	return function(){
		try{
			return func.apply(window, arguments);
		} catch(e){
			// console.debug('formatter', 'initFilter', key, arguments, e);
			return arguments[0];
		}
	}
}

var formatter = {
	install(Vue) {
		//console.log('formatter.install', this);
		Vue.prototype.$format = formatter;

		for(var key in this){
			if(key != 'install' && typeof this[key] == 'function'){
				Vue.filter(key, initFilter(this[key]));
			}
		}
		//Vue.filter("number", formatter.number);
		//Vue.filter("date", formatter.date);
	},

	MAP_GENDER : {'M': '남', 'F': '여'},
	MAP_CELL_FIRST : {'010':'휴대전화', '011':'휴대전화(SKT)', '016':'휴대전화(KT)', '017':'휴대전화(신세기통신)', '018':'휴대전화(한솔PCS)', '019':'휴대전화(LGT)'},
	MAP_TELL_FIRST : {'02': '서울특별시', '031':'경기도', '032':'인천광역시', '033':'강원도', '041':'충췅북도', '042':'대전광역시', '043':'충청북도', '044':'세종특별자치시', '051':'부산광역시', '052':'울산광역시', '053':'대구광역시', '054':'경상북도', '055':'경상남도', '061':'전라남도', '062':'광주광역시', '063':'전라북도', '064':'제주특별자치도', '070':'인터넷전화', '080':'수신자부담전화', '0505':'평생전화'},
	ARR_NUMBER_KOR : ['','만','억','조','경'],

	number(val, type){
		//console.log('formatter.number', typeof val, val, type);
		//포맷 제거 string type 리턴
		if(type == 'string') return new String(val).replace(/\D/g, '');
		//포맷 제거 number type 리턴
		else if(type == 'number') return parseFloat(formatter.number(val, 'string'));
		//포맷 적용.
		else return new String(parseInt(val)).replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	},

	numKr(val, type){
		var result = '';
		if(!type){
			var num = parseInt((val + '').replace(/[^0-9]/g, ''), 10) + '';
			var unitCnt = Math.ceil(num.length / 4); //단위 갯수. 숫자 10000은 일단위와 만단위 2개이다.
			num = num.padStart(unitCnt * 4, '0') //4자리 값이 되도록 0을 채운다
			var arr = num.match(/[\w\W]{4}/g);

			for(var i in arr){
				result += formatter.number(parseInt(arr[i])) + formatter.ARR_NUMBER_KOR[unitCnt - i - 1] + ' ';
			}
		}
		return result.trim();
	},

	months(val, type){
		//console.log('formatter.months', typeof val, val);
		if(type == 'string'){
			//포맷팅 제거 string type 리턴
			return '' + formatter.months(val, 'number')
		} else if(type == 'number'){
			//포맷팅 제거 number type 리턴
			if(typeof val == 'number' || /^[(0-9)]$/.test(val)){
				return parseInt(val);
			} else if(/^([(0-9)]{1,2}년)?\s?([(0-9)]{1,2}개월)?$/.test(val)){
				var sp = (val+'').split(' ');
				var y = 0, m = 0, i = 0;
				if(sp[i].indexOf('년') > 0){
					y = parseInt(sp[i].replace('년', ''));
					i ++;
				}
				if(sp.length > i && sp[i].indexOf('월') > 0){
					m = parseInt(sp[i].replace('월', ''));
				}
				return (y * 12 + m)
			}
			return 0;
		} else {
			//포맷팅 적용
			if(val <= 12) return val + '개월';
			var year = parseInt(val / 12);
			var month = val % 12;
			return (year > 0? year + '년': '') + (year > 0&& month > 0 ? ' ' : '') + (month > 0 ? month + '개월' : '');
		}
	},

	gender(val, type){
		if(type == 'string'){
			for(var key in formatter.MAP_GENDER){
				if(formatter.MAP_GENDER[key] == val)
					return key;
			}
			return val;
		} else {
			return formatter.MAP_GENDER[val] || val;
		}
	},

	age(val){
		if(typeof val != 'string') return val;
		var today = new Date();
		var birthDate = formatter.date(val, 'date')
		let age = today.getFullYear() - birthDate.getFullYear();
		const m = today.getMonth() - birthDate.getMonth();
		if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
			age--;
		}
		return age;
	},

	birthYear(val, type){
		if(!val) return val;
		if(type == 'string'){
			if(/[(0-9)]{2}[ 년생]/.test(val)){
				var temp = val.substr(0, 2);
				if('20' + temp > new Date().format('yyyy')){
					return '19' + temp;
				} else {
					return '20' + temp;
				}
			} else if(/[(0-9)]{4}/.test(val)){
				return val;
			} else {
				return '';
			}
		} else {
			return val.substr(2, 2) + ' 년생';
		}
	},

	cell(val, type){
		var msg = '전화번호 형식이 아닙니다.';
		if(!val) return val;

		//입력값을 3자리로 구분하여 유효성 체크
		var nums = [];
		if(val.split('-').length > 1){
			nums = val.split('-');
		} else if(val.length < 10){
			throw new PronError('FFF', msg);
		} else {
			nums[0] = val.slice(0, 3), nums[1] = val.slice(3, -4), nums[2] = val.slice(-4);
		}
		if(nums.length != 3) throw new PronError('FFF', msg);
		
		//첫자리가 휴대폰 번호가 맞는지 체크.
		if(Object.keys(formatter.MAP_CELL_FIRST).indexOf(nums[0]) < 0) throw new PronError('FFF', msg);

		//2번째 번호 첫자리 체크
		if(nums[1].split(0, 1) == '0') throw new PronError('FFF', msg);

		//2번째 번호 자리수 체크
		if([3, 4].indexOf(nums[1].length) < 0) throw new PronError('FFF', msg);
		//3번째 번호 자리수 체크
		if([4].indexOf(nums[2].length) < 0) throw new PronError('FFF', msg);

		//포맷팅 적용
		if(type == 'string')
			return nums[0] + '' + nums[1] + '' + nums[2];
		else 
			return nums[0] + '-' + nums[1] + '-' + nums[2];
	},

	tell(val, type){
		var msg = '전화번호 형식이 아닙니다.';
		if(!val) return val;

		//입력값을 3자리로 구분하여 유효성 체크
		var nums = [];
		if(val.split('-').length > 1){
			nums = val.split('-');
		} else if(val.length < 9){
			throw new PronError('FFF', msg);
		} else {
			if(val.slice(0, 2) == '02')
				nums[0] = val.slice(0, 2), nums[1] = val.slice(2, -4), nums[2] = val.slice(-4);
			else if(val.slice(0, 2) == '0505')
				nums[0] = val.slice(0, 4), nums[1] = val.slice(4, -4), nums[2] = val.slice(-4);
			else
				nums[0] = val.slice(0, 3), nums[1] = val.slice(3, -4), nums[2] = val.slice(-4);
		}
		if(nums.length != 3) throw new PronError('FFF', msg);
		
		//첫자리가 지역번호가 맞는지 체크.
		if(Object.keys(formatter.MAP_TELL_FIRST).indexOf(nums[0]) < 0) throw new PronError('FFF', msg);

		//2번째 번호 첫자리 체크
		if(nums[1].split(0, 1) == '0') throw new PronError('FFF', msg);

		//2번째 번호 자리수 체크
		switch(nums[0]){
			case '02' :
			case '070':
				if([3, 4].indexOf(nums[1].length) < 0) throw new PronError('FFF', msg);
				break;
			default :
				if([3].indexOf(nums[1].length) < 0) throw new PronError('FFF', msg);
				break;
		}
		//3번째 번호 자리수 체크
		if([4].indexOf(nums[2].length) < 0) throw new PronError('FFF', msg);

		//포맷팅 적용
		if(type == 'string')
			return nums[0] + '' + nums[1] + '' + nums[2];
		else 
			return nums[0] + '-' + nums[1] + '-' + nums[2];
	},

	phone(val, type){
		try{
			return formatter.cell(val, type);
		} catch (e){
			return formatter.tell(val, type);
		}
	},

	date(val, type, nowFormat){
		//if(!val) return val;
		//console.log('formatter.date', typeof val, val, format, nowFormat);
		var errMsg = '숫자로만 입력 해 주세요.'
		var format = type;

		var date = new Date();
		if(val instanceof Date) date = val;
		else if(typeof val == 'number') date = new Date(val);
		else if(typeof val == 'string') {
			var yyyy, MM, dd, HH, mm, ss;
			if(typeof nowFormat == 'string'){
				//현재 포맷에 따라 값 추출
				var idx = -1;
				if((idx = format.indexOf(FORMAT_YEAR))   >= 0) yyyy = val.substr(idx, 4);
				if((idx = format.indexOf(FORMAT_MONTH))  >= 0) MM   = val.substr(idx, 2);
				if((idx = format.indexOf(FORMAT_DAY))    >= 0) dd   = val.substr(idx, 2);
				if((idx = format.indexOf(FORMAT_HOUR))   >= 0) HH   = val.substr(idx, 2);
				if((idx = format.indexOf(FORMAT_MINUTE)) >= 0) mm   = val.substr(idx, 2);
				if((idx = format.indexOf(FORMAT_SECOND)) >= 0) ss   = val.substr(idx, 2);
			} else {
				if(new RegExp("^(\\d{4})" + SEP_YMD + "(\\d{2}|)" + SEP_YMD + "(\\d{2}|)" + SEP_YMDTIME + "(\\d{2}|)" + SEP_TIME + "(\\d{2}|)" + SEP_TIME + "(\\d{2}|)$").test(val) == false){
					// 숫자 4자리로 시작.
					// 월일시분초 는 숫자 2자리 또는 없음.
					// 년월일 구분자는 [-/.] 또는 없음.
					// 년월일과 시간 사이 구분은 공백문자 또는 없음.
					// 시간 구분자는 [:] 또는 없음.
					if(type == 'string') return val.replace(/\D/g, '');
					else throw new PronError('FFF', errMsg);
				}
				//자릿수에 따라 값 추출
				var val2 = val.replace(new RegExp(SEP_YMD.replace(/^[[]/g, '').replace(/[\]][?]?$/g, '') + SEP_YMDTIME.replace(/^[[]/g, '').replace(/[\]][?]?$/g, '') + SEP_TIME.replace(/^[[]/g, '').replace(/[\]][?]?$/g, '')), '');
				yyyy = val2.substring(0, 4);
				MM   = val2.substring(4, 6);
				dd   = val2.substring(6, 8);
				HH   = val2.substring(8, 10);
				mm   = val2.substring(10, 12);
				ss   = val2.substring(12, 14);
			}

			//출력 포맷이 없을 경우 입력 포맷에 따라 출력 포맷 정의
			if(!format || format == 'string') {
				format = "";
				if(yyyy) format += FORMAT_YEAR;
				if(MM)   format += FORMAT_MONTH;
				if(dd)   format += FORMAT_DAY;
				if(HH)   format += FORMAT_HOUR;
				if(mm)   format += FORMAT_MINUTE;
				if(ss)   format += FORMAT_SECOND;
			} else {
				if(!yyyy && format.indexOf(FORMAT_YEAR)   >= 0) throw new PronError('FFF', errMsg);
				if(!MM   && format.indexOf(FORMAT_MONTH)  >= 0) throw new PronError('FFF', errMsg);
				if(!dd   && format.indexOf(FORMAT_DAY)    >= 0) throw new PronError('FFF', errMsg);
				if(!HH   && format.indexOf(FORMAT_HOUR)   >= 0) throw new PronError('FFF', errMsg);
				if(!mm   && format.indexOf(FORMAT_MINUTE) >= 0) throw new PronError('FFF', errMsg);
				if(!ss   && format.indexOf(FORMAT_SECOND) >= 0) throw new PronError('FFF', errMsg);
			}
			date = new Date(yyyy, MM?MM - 1 : 0, dd||1, HH||0, mm||0, ss||0);

			//입력값 정합성 확인
			if(yyyy && yyyy != date.getFullYear())  throw new PronError("FFF", errMsg);
			if(MM   && MM   != date.getMonth() + 1) throw new PronError("FFF", errMsg);
			if(dd   && dd   != date.getDate())      throw new PronError("FFF", errMsg);
			if(HH   && HH   != date.getHours())     throw new PronError("FFF", errMsg);
			if(mm   && mm   != date.getMinutes())   throw new PronError("FFF", errMsg);
			if(ss   && ss   != date.getSeconds())   throw new PronError("FFF", errMsg);
		}

		//console.debug('formatter.date', 'date', type, format, date);
		if(!type) format = FORMAT_DATE;
		else if(type == 'date')   return date;
		else if(type == 'number') return date.getTime();
		else if(type == 'string' && format == 'string'){
			return date.format(FORMAT_DATE);
		}
		
		return date.format(format);
	},

	bizNo(val, type){
		//console.log('formatter', 'bizNo', val)
		if(typeof val == 'string' && val != ""){
			if(type == 'string'){
				return val.replace(/-/g, '');
			} else if(!isNaN(val) && val.length == 10) {
				try{
					// 넘어온 값의 정수만 추츨하여 문자열의 배열로 만들고 10자리 숫자인지 확인합니다.
					//if ((bisNo = (bisNo+'').match(/\d{1}/g)).length != 10) { return false; }

					// 합 / 체크키
					var sum = 0, key = [1, 3, 7, 1, 3, 7, 1, 3, 5];

					// 0 ~ 8 까지 9개의 숫자를 체크키와 곱하여 합에더합니다.
					for (var i = 0 ; i < 9 ; i++) { sum += (key[i] * Number(val[i])); }
			
					// 각 8번배열의 값을 곱한 후 10으로 나누고 내림하여 기존 합에 더합니다.
					// 다시 10의 나머지를 구한후 그 값을 10에서 빼면 이것이 검증번호 이며 기존 검증번호와 비교하면됩니다.
					
					// 체크섬구함
					var chkSum = 0;
					chkSum = Math.floor(key[8] * Number(val[8]) / 10);
			
					// 체크섬 합계에 더해줌
					sum +=chkSum;
					var reminder = (10 - (sum % 10)) % 10;
			
					//값 비교
					//console.log('formatter', 'bizNo', reminder, val)
					if(reminder==Number(val[9])) {
						return val.replace(/(\d{3})(\d{2})(\d{5})/, (type != 'masking')?'$1-$2-$3':'$1-$2-*****');
					}
				} catch(e) { 
					//console.warn('formatter', 'bizNo', e) 
				}
			}
			throw new PronError("FFF", "사업자등록번호가 맞지 않습니다.");
		}
		return val;
	},

	url(val, type){
		//console.debug('formatter', 'url', val, type, typeof type)
		if(!val) return val;
		if(typeof val != 'string') return val;
		if(type == 'string'){
			if(val.indexOf(location.host) >= 0){
				if(val.startsWith('http://' )) return val.replace('http://'+location.host, '');
				if(val.startsWith('https://')) return val.replace('https://'+location.host, '');
			}
			return val;
		} else {
			if(val.startsWith('http://') || val.startsWith('https://')) return val;
			if(val.startsWith('/')) return location.origin + val;
			throw new PronError('FFF', '정상적인 URL 형식이 아닙니다.');
		}
	},

	mask(val, type){
		//console.debug('formatter', 'mask', val, type, typeof type)
		if(typeof val != 'string') return val;
		var regex = '(?<=.{2}).';
		if(typeof type == 'number'){
			regex = '(?<=.{' + type + '}).';
		} else if(typeof type == 'string'){
			switch(type){
				case 'email':
					regex = '.(?=.{0,' + (val.split('@')[0].length - 3) + '}@)';
					break;
				default : 
					regex = type;
					break;
			}
	
		}

		return val.replace(new RegExp(regex, 'gi'), '*');
	},

	ellipsis(val, length) {
		var formatVal = val || '';
		if(formatVal.length > length){
			formatVal = formatVal.substring(0, length>=1 ? length-1 : length) + "...";
		}
		return formatVal;
	}

}

export default formatter;