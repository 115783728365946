<template>
	<div class="CalandarWrap" :class="{'layer': this.layer}" v-show="show">
		<div class="header">
			<div class="year"><span @click="add(-1, 'year')">&lt;&lt;</span><span>{{year}}</span><span @click="add(1, 'year')">&gt;&gt;</span></div>
			<div class="month"><span @click="add(-1, 'month')">&lt;&lt;</span><span>{{month}}</span><span @click="add(1, 'month')">&gt;&gt;</span></div>
			<button v-if="this.layer" @click="close" class="close">닫기</button>
		</div>
		<body class="body">
			<div v-for="(days, index) in weeks" :key="index" class="week">
				<span v-for="day in days" :key="day.key" :class="day.info" class="day" @click="click(day)">{{day.day}}</span>
			</div>
		</body>
	</div>
</template>

<script>
/**
 * Calenar 선택
 * 
 * 사용법)
 *	  props   : 
 *				value : 날짜
 *				start : 시작날짜
 *				end   : 종료날짜
 *				layer : 레이어팝업으로 띄울지 여부
 *	  emit	:  
 *				input : input 값 변경이 일어날 때 작동 (v-model 로 모델 바인딩 시 양방향 데이터바인딩)
 * 				close : 닫기 이벤트. layer 값이 true 일 때 동작.
 * 예시)
 *	  <InputComp type="number" v-model="format.number" title="숫자"/>
 * 
 */
import formatter from '@/assets/js/formatter'

export default {

	props: {
		value : { required: true },
		start : { },
		end   : { },
		layer : { }
	},
	data() {
		var ret = {};
		var date = new Date();
		try {
			date = formatter.date(this.value, 'date');
		} catch(e){
			//console.warn('CalendarComp', this.value);
		}
		ret.show   = (this.layer) ? false : true;
		ret.year   = date.getFullYear();
		ret.month  = date.getMonth() + 1;
		ret.inType = typeof this.value;
		if(ret.inType == 'object'){
			if(this.value instanceof Date) ret.inType = 'date';
		}
		
		//console.log('CalendarComp', 'data', ret);
		return ret;
	},

	methods : {
		add(dist, type){
			var date = new Date(this.year, this.month-1, 1).add(dist, type);
			if(dist < 0 && this.start && date.format('yyyyMM') < formatter.date(this.start, 'yyyyMM')){
				alert('날짜는 ' + formatter.date(this.start, 'yyyy-MM-dd') + '부터 선택할 수 있습니다.');
			} else if(dist > 0 && this.end && date.format('yyyyMM') > formatter.date(this.end, 'yyyyMM')){
				alert('날짜는 ' + formatter.date(this.end, 'yyyy-MM-dd') + ' 까지 선택할 수 있습니다.');
			} else {
				this.year  = date.getFullYear();
				this.month = date.getMonth() + 1;
			}
		},

		click(day){
			var date  = formatter.date(day.key, this.inType);
			//console.log('CalendarComp', 'click init', date, this.start, this.end);
			var dateF  = typeof date == 'string' ? date : formatter.date(day.key);
			var startF = (typeof this.start == 'number' && formatter.date(new Date().add(this.start))) || (this.start && formatter.date(this.start));
			var endF   = (typeof this.end   == 'number' && formatter.date(new Date().add(this.end)))   || (this.end   && formatter.date(this.end));
			//console.log('CalendarComp', 'click format', dateF, startF, endF);

			// 날짜 정합성 체크
			if((startF && dateF < startF) || (endF && dateF > endF)){
				startF = startF && formatter.date(startF, 'yyyy-MM-dd');
				endF   = endF   && formatter.date(endF, 'yyyy-MM-dd');
				if(typeof this.start == 'number'){
					if(this.start > 0)		startF = this.start + '일 이후(' + startF + ')';
					else if(this.start < 0) startF = (-this.start) + '일 이전(' + startF + ')';
					else					startF = '오늘(' + startF + ')';
				}
				if(typeof this.end == 'number'){
					if(this.end > 0)		endF = this.end + '일 이후(' + endF + ')';
					else if(this.end < 0)	endF = (-this.end) + '일 이전(' + endF + ')';
					else					endF = '오늘(' + endF + ')';
				}
				if(startF && endF){
					alert('날짜는 ' + startF + ' 부터 ' + endF + ' 까지 선택할 수 있습니다.');
				} else if(startF){
					alert('날짜는 ' + startF + ' 부터 선택할 수 있습니다.');
				} else if(endF){
					alert('날짜는 ' + endF + ' 까지 선택할 수 있습니다.');
				}
			} else {
				this.$emit('input', date);
				if(this.layer){
					this.close(date);
				}
			}
		},

		open(){
			this.show = true;
		},

		close(ret){
			this.show = false;
			this.$emit('close', ret);
		}
	},

	computed : {
		weeks(){
			var weeks = [];
			var date = new Date(this.year, this.month-1, 1);
			var baseMonth = date.format('yyyyMM')
			var today = new Date().format('yyyyMMdd');
			var select = null;
			if(this.value){
				if(this.value instanceof Error) alert(this.value.message); /*console.warn('CalendarComp', this.value.message);*/
				else select = formatter.date(this.value, 'yyyyMMdd');
			}

			date.add(-date.getDay(), 'day');
			while(date.format('yyyyMM') <= baseMonth){
				var days = [];
				for(var i=0; i<7; i++){
					var day = {
						key   : date.format('yyyyMMdd'),
						year  : date.getFullYear(),
						month : date.getMonth() + 1,
						day   : date.getDate(),
						info  : {}
					};
					//console.log('CalendarComp', 'weeks', day, this.month, this.today)
					day.info['week' + i] = true;
					day.info['today'   ] = day.key   == today;
					day.info['select'  ] = day.key   == select;
					day.info['month'   ] = day.month == this.month;
					days.push(day);
					date.add(1, 'day');
				}
				weeks.push(days);
			}
			//console.log('CalendarComp', 'weeks', weeks)
			return weeks;
		}
	}
}
</script>
<style scoped>
	.CalandarWrap {
		border: 1px solid #666;
		overflow: hidden;
		background: #fff;
		width: 300px;
	}
	.CalandarWrap.layer {
		position: absolute;
		z-index: 500;
	}
	.close { position: absolute; right: 0; }
	.header>div { display: inline-block; }
	.header span {padding : 5px; }
	.day { display: inline-block; width:20px; height: 20px; padding:10px; text-align: center; background-color: whitesmoke; border:1px solid white; }
	.day.month { background-color: white; }
	.day.week0 { color: red }
	.day.week6 { color: blue }
	.day.today { background-color : yellowgreen }
	.day.select { border:1px solid red; }
</style>