<template>
	<component :is="tagName || 'span'" class="selectComp" :class="{'select_wrap' : type == 'select', 'checkbox' : ['checkbox', 'yn'].indexOf(type) >= 0 }">
		<select v-if="type == 'select'" @change="onChange" :disabled="disabled" ref="input">
			<option value="" :disabled="isAll === undefined" :hidden="isAll === undefined" :selected="checkSelect()">
				<template v-if="title">{{title}}</template>
				<template v-else-if="isAll">- 전 체 -</template>
				<template v-else>- 선 택 -</template>
			</option>
			
			<template v-for="code in selectList">
				<option :key="code[codeKey]" v-if="checkFilter(code)" :value="code[codeKey]" :selected="checkSelect(code)">
					<slot :item="code">{{ code[nameKey] }}</slot>
				</option>
			</template>
		</select>
		<template v-else-if="type == 'yn'">
			<input type="checkbox" :id="scrId+'_yn'" :checked="value == 'Y'" value="Y" @change="onChange" :disabled="disabled" ref="input">
			<label v-if="title" :for="scrId+'_yn'"><span class="rd"></span><slot> {{ title }}</slot></label>
		</template>
		<template v-else-if="type == 'text_comma_split'">
			<template v-for="(v, index) in bindValue">
				<template v-if="index > 0">,</template>
				<template v-for="code in selectList">
					<template v-if="v == code['cd']"><slot :item="code">{{ code[nameKey] }}</slot></template>
				</template>				
			</template>
		</template>
		<template v-else>
			<template v-for="(code, index) in selectList">
				<template v-if="type == 'text'">
					<template v-if="checkSelect(code)"><slot :item="code">{{ code[nameKey] }}</slot></template>
				</template>
				<template v-else>
					<template v-if="checkFilter(code)">
						<span v-if="index != 0 && separator !== false" :key="'span1_'+code[codeKey]"><template v-if="separator">{{separator}}</template><nbsp v-if="index % 10 != 0" n="1" /></span>
						<input :type="type" :name="scrId" :id="scrId+'_'+code[codeKey]" :value="code[codeKey]" :checked="checkSelect(code)" @change="onChange" :disabled="disabled" ref="input" :key="'input_'+code[codeKey]" :data-index="index">
						<label :for="scrId+'_'+code[codeKey]" :key="'label_'+code[codeKey]">
							<slot :item="code" :index="index">
								<span class="rd"></span> {{ code[nameKey] }}
							</slot>
						</label>
						<br v-if="index % 9 == 0 && index != 0" :key="'br_'+code[codeKey]" />
					</template>
				</template>
			</template>
		</template>
		<!--span class="error" v-if="error && showError" :style="{top: ($el ? ($el.offsetHeight + 1) : 0) + 'px'}">{{error.message}}</span-->
		<errorComp ref="error" :value="value" :title="title" :showError="showError"/>
	</component>
</template>

<script>
/**
 * type
 * 		text_comma_split : value 에 콤마 구분자의 코드값들에 대해서 공통코드 값을 콤마 구분자로 텍스틀 출력
 * 		ex) <SelectComp type="text_comma_split" cdId="PRO106" value="01,02,03"/>
 * 			출력 -> 초급,중급,고급
 * 
 */
import PronError from '/src/assets/js/PronError'
import errorComp from "@/components/ErrorComp.vue";
export default {
	components:{errorComp},
	props: {
		type	: { type: String, default: 'select' },
        cdId	: { type: String, default: '' },
		list	: { },
        isAll	: { type: Boolean, default: undefined },
        value	: { },
		defValue: { },
		title   : { type: String },
		codeKey	: { type: String, default: 'cd' },
		nameKey	: { type: String, default: 'cdNm' },
		filter  : { type: String, default: '' },
		separator : { type: String },
		disabled : { },
		tagName	: String,
		
		showError : { type: Boolean, default : true },
		rules : { },
	},
	data() {
		//console.debug('SelectComp', 'data', this.value, this.defValue);
        return { 
			val: this.value,
			cdList : [],
		}
	},
	watch: {
		value(){
			if(this.value instanceof Error){
				//console.debug('SelectComp', 'watch')
				/*if(Array.isArray(this.$refs.input)){
					this.$refs.input[0].focus();
				} else {
					this.$refs.input.focus();
				}*/
			} else if(this.val != this.value){
				this.val = this.value;
				this.validate();
			}
		},
		rules(){
			this.validate();
		}
	},
    mounted() {
       if(this.cdId){
			//공통코드 사용.
			this.$store.dispatch("commonCode").then((res) => {
				this.cdList = res[this.cdId];
				this.validate();
			});
		} else {
			this.validate();
		}
    },
	methods : {
		//선택여부
		checkSelect(code){
			var val = this.bindValue || '';//this.value || this.defValue || '';
			//console.log('checkSelect', code, val, typeof val)
			if(!code){
				if(val == '' || val instanceof Error){
					//console.log('checkSelect11111', code, val, typeof val)
					return true;
				} else if(this.filter && val.indexOf(this.filter) != 0){
					//console.log('checkSelect22222', code, val, typeof val)
					this.$emit('change', {}, '');
					this.emitInput('');
					return true;
				} else {
					//console.log('checkSelect33333', code, val, typeof val)
					return false;
				}
			} else if(!val){
				return !code;
			} else if(this.type == 'checkbox'){
				return val.indexOf(code[this.codeKey]) > -1
			} else {
				return val == code[this.codeKey];
			}
		},
		checkFilter(code){
			//console.debug('SelectFilter', 'checkFilter', this.filter, code, this.filter == '' || code[this.codeKey].indexOf(this.filter) == 0);
			return 	this.filter == '' || code[this.codeKey].indexOf(this.filter) == 0;
		},
		//change Event
       	onChange(event) {
		   	//console.log('SelectComp', 'onchange', event.target.value, event.target.checked, this.value);
			var result = '';
			var item = undefined;
			if(this.type == 'checkbox'){
				//checkbox : return Array.
				result = this.bindValue;

				var val = event.target.value;
				if(event.target.checked){
					result.push(val);
					result.sort();
				} else {
					var idx = result.indexOf(val);
					if(idx >= 0){
						result.splice(idx, 1)
					}
				}
				
				//이벤트가 일어난 객체를 구한다.
				if(event.target.dataset.index != undefined){
					item = this.selectList[event.target.dataset.index];
				} else {
					item = val;
				}

				//value의 type이 string이면 strging로 변환한다.
				if(typeof this.value == 'string'){
					result = result.join();
				}
			} else if(this.type == 'yn'){
				result = event.target.checked ? 'Y' : 'N';
				item = result;
			} else {
				result = event.target.value;
				if(this.type == 'select'){
					//selectbox 의 선택된 index 로 객체를 구한다.
					var index = event.target.selectedIndex - (this.isAll ? 2 : 1);
					if(index >= 0){
						item = this.selectList[index];
					} else {
						item = result;
					}
				} else {
					item = result;
				}
			}
			this.val = result;
			//console.debug('SelectComp', 'onChange', result, item);
			if(this.validate()){
				this.emitInput(result);
				this.$nextTick(() => { this.$emit('change', event, result, item); })
			}
       	},
		emitInput(val){
			try{
				//console.debug('selectComp', 'emit', val);
				if(val instanceof Error){
					if(this.value instanceof Error && this.value.message == val.message){
						//console.debug('selectComp', 'emitInput', val.message);
					} else {
						//if(!val.details) val.details = this.$refs.input;
						this.$emit('input', val);
					}
				} else {
					if(this.value == val){
						//console.debug('selectComp', 'emitInput', val);
					} else {
						this.$emit('input', val);
					}
				}
			} catch(e) {
				//console.warn('selectComp', 'emit error', e);
				if(!e.details) e.details = this.$refs.input;
				this.$emit('input', e);
			}
		},
		
		validate(){
			var result = true;
			if(this.rules){
				switch(typeof this.rules){
					case 'object' :
						for(var key in this.rules){
							var obj  = this.rules[key];
							var rule = undefined;
							var args = undefined;
							var msg  = undefined;
							if(typeof key == 'string') rule = key;
							if(typeof obj == 'object'){
								if(obj.rule !== undefined) rule = obj.rule;
								if(obj.args !== undefined) args = obj.args;
								if(obj.msg  !== undefined) msg  = obj.msg;
							} else {
								args = this.rules[key];
							}
							
							result = this.ruleCheck(rule, args, msg);
							if(result !== true) return false;
						}
						break;
					case 'string' :
						var rules = this.rules.split(',');
						for(var i in rules){
							var sp = rules[i].split(':');
							result = this.ruleCheck(sp[0], sp.slice(1));
							if(result !== true) return false;
						}
						break;
					case 'function':
						result = this.ruleCheck(this.rules);
						if(result !== true) return false;
				}
			}

			return result;
		},
		ruleCheck(rule, args, msg){
			if(this.val instanceof Error) return false;
			var v = this.val || '';
			var title = '[' + (this.title ? this.title: v) + '] ' ;
			var result = true;
			if(typeof args == 'function'){
				result = args(v, msg)
			} else if(typeof rule == 'function'){
				result = rule(v, args, msg)
			} else if(v == '') {
				args = Array.isArray(args) ? args : [ args ];
				if(rule == 'required' && args[0] !== false && args[0] !== 'false') result = new PronError('IRREQD', msg || (title + '필수 입력사항 입니다.'));
			} else if(rule != 'required') {
				args = Array.isArray(args) ? args : [ args ];
				var baseRules = {}

				if(baseRules[rule]) result = baseRules[rule]();
				else result = new PronError('IRNONE', title + '의 룰을 확인 할 수 없습니다.(' + rule + ')');
			}
			
			if(result !== true){
				var errCode = 'IRERR'
				/*if(result instanceof Error) console.debug('selectComp', 'ruleCheck', rule, v, result.code, result.message);
				else*/ if(typeof result == 'string') result = new PronError(errCode, result);
				else result = new PronError(errCode, title + '입력 룰 검증에 실패했습니다.');

				this.emitInput(result);
				return result;
			}
			return true;
		},
	},

	computed : {
		bindValue(){
			//console.debug('SelectComp', 'val', this.value, this.defValue);
			if(!this.value && this.defValue){
				this.emitInput(this.defValue);
			}

			if(['checkbox', 'text_comma_split'].indexOf(this.type) >= 0){
				if(typeof this.value == 'string' && this.value){
					return this.value.split(',');
				}
				return this.value || [];
			}
			return this.value;
		},
		scrId(){
			return this.cdId + this.type.substr(0,1).toUpperCase() + this._uid;
		},
		selectList(){ 
			//console.log('SelectComp', 'selectList', this.type, this.cdId, this.value);
			var selectList = []
			if(this.cdId){
				//공통코드 사용.
				selectList = this.cdList;
			} else if(Array.isArray(this.list)){
				//list가 Array 일 경우
				selectList = this.list;
			} else if(typeof this.list == 'object'){
				//list가 Object일 경우
				for(var cd in this.list){
					switch(typeof this.list[cd]){
						case 'string':
						case 'number':
							var item1 = {};
							item1[this.codeKey] = cd;
							item1[this.nameKey] = this.list[cd];
							selectList.push(item1);
					}
				}
			} else if(typeof this.list == 'string'){
				//list가 string 일 경우
				var list = this.list.split(',');
				for(var i in list){
					var sp = list[i].split(':');
					if(sp.length >= 2){
						var item2 = {};
						item2[this.codeKey] = sp[0];
						item2[this.nameKey] = sp[1];
						selectList.push(item2);
					}
				}
			}

			return selectList;
		},
		error(){
			if(this.value instanceof Error)
				return this.value;
			else 
				return false;
		}
	},
}
</script>
<style scoped>
.selectComp {
	position: relative;
	overflow: initial;
}
</style>