import Vue from 'vue'
import axios from 'axios'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		menu: getSessionStorage('menu', 'json', { 'MENU_HOME':[], 'MENU_LOGN' : [], 'MENU_JOIN':[], 'MENU_MYPG' : [], 'MENU_ALRM':[], 'MENU_0001' : [], 'MENU_0002' : [], 'MENU_0003' : [], 'MENU_ETC_':[], 'MENU_GUID':[], 'ADMIN_HOME':[], 'ADMIN_LOGN':[], 'ADMIN_MENU':[] }),
		logConfig: getSessionStorage('logConfig', 'json', { debug: true, useTag: false }),
		userToken: getSessionStorage('user_token', 'json', { token: '', token_expire: 0, token_start : 0 }),
		userInfo: getSessionStorage('user_info', 'json', { mberSeq:'', mberDivCd:'', loginId:'', mberNm:'', birthYyyymmdd:'', gender:'', notiNewCnt:0, keepLoginYn:'', }),
		commonCode: getLocalStorage('common_code', 'json', {}),//{ clCode : {}, code : {}, codeDetail : {}, loaded : 0 },//
		ncsLevelCode: getSessionStorage('ncs_level_code', 'json', {}),
		ncsDetailClassCode: getSessionStorage('ncs_detail_class_code', 'json', {}),
		licenseCode: getSessionStorage('license_Code', 'json', {}),
		profile : getSessionStorage('profile', 'json', {name : ''}),
		//sessionTime : { timeout : 1000 * 60 * 30, beforeAlert : 1000 * 60 * 5 }, //세션타임 설정 (timeout: 30분 / beforeAlert : 5분)
		sessionTime : { timeout : 1000 * 60 * 120, beforeAlert : 1000 * 30 * 120 }, //세션타임 설정 (timeout: 30분 / beforeAlert : 5분)
		loading : true,
		history: getSessionStorage('history', 'json', { }),
	},
	getters: {
		isAdmin(state) {
			return state.userInfo && state.userInfo.mberDivCd.substr(0, 1) == '0';
		} 
	},
	mutations: {

		menu(state, payload) {
			//console.log('menu', payload);
			for(var key in payload){
				state.menu[key] = payload[key];
			}
		},

		profile(state, payload) {
			if(payload && payload.name){
				state.profile.name = payload.name;
			}
		},

		loading(state, payload) {
			// console.log('loading', payload);
			state.loading = payload === true;
		},
		logConfig(state, payload) {
			state.logConfig = state.logConfig || {};
			if (typeof payload == 'object') {
				state.logConfig.debug = payload.debug == true;
				state.logConfig.useTag = payload.useTag || false;
			} else {
				state.logConfig.debug = payload == true;
			}
			setSessionStorage('logConfig', state.logConfig);
		},

		userToken(state, payload) {
			//JWT Access Token
			//alert("state" + Object.keys(state).length);
			//alert("payload" + Object.keys(payload).length);
			// console.log("userToken", "state", state);
			// console.log("userToken", "payload", payload);

			// for(var i = 0; i < Object.keys(payload).length; i++) {
			// 	alert(Object.keys(payload)[i] + " : " + Object.values(payload)[i]);
			// }

			if(payload && payload.access_token){
				state.userToken.token = payload.access_token;
				state.userToken.token_start = new Date().getTime();
				state.userToken.token_expire = new Date().add(payload.expires_in - 3, 'second').getTime();
			} else {
				state.userToken.token = '';
				state.userToken.token_start = 0;
				state.userToken.token_expire = 0;
			}
			
			setSessionStorage('user_token', state.userToken);
		},

		userInfo(state, payload) {
			if(payload && payload.mberNm){
				state.userInfo.mberSeq = payload.mberSeq;
				state.userInfo.mberDivCd = payload.mberDivCd;
				state.userInfo.loginId = payload.loginId;
				state.userInfo.mberNm = payload.mberNm;
				state.userInfo.birthYyyymmdd = payload.birthYyyymmdd;
				state.userInfo.gender = payload.gender;
				state.userInfo.notiNewCnt = payload.noti.notiNewCnt;
				state.userInfo.keepLoginYn = payload.keepLoginYn;
			} else {
				state.userInfo.mberSeq = '';
				state.userInfo.mberDivCd = '';
				state.userInfo.loginId = '';
				state.userInfo.mberNm = '';
				state.userInfo.birthYyyymmdd = '';
				state.userInfo.gender = '';
				state.userInfo.notiNewCnt = 0;
				state.userInfo.keepLoginYn = '';
			}
			setSessionStorage('user_info', state.userInfo);
		},

		notiNewCnt(state, payload) {
			state.userInfo.notiNewCnt = payload;
		},

		commonCode(state, data) {
			// console.log('$store.commonCode', data);
			var commonCode = state.commonCode;
			commonCode.loaded = new Date().getTime();

			var i, key;

			// code
			if (Array.isArray(data.code) && data.code.length > 0) {
				var code = data.code;
				commonCode.code = commonCode.code || {};
				for (i in code) {
					key = code[i].cdId;
					if (code[i].useYn == 'N') {
						delete commonCode.code[key];
					} else {
						commonCode.code[key] = code[i];
					}
				}
			}

			var codeDetailTemp = {};

			//codeDetail (임시로 Map에 넣고 아래에서 배열로 정렬해서 다시 넣도록 함)
			if (Array.isArray(data.codeDetail) && data.codeDetail.length > 0) {
				var codeDetail = data.codeDetail;
				//state.code.codeDetail = state.code.codeDetail || {};
				codeDetailTemp= {};
				for (i in codeDetail) {
					var key1 = codeDetail[i].cdId;
					var key2 = codeDetail[i].cd;
					codeDetailTemp[key1] = codeDetailTemp[key1] || {};
					if (codeDetail[i].useYn == 'N') {
						//delete state.code.codeDetail[key1][key2];
					} else {
						codeDetailTemp[key1][key2] = codeDetail[i];
					}
				}
			}

			commonCode.codeDetail = {};

			// codeDetail 정렬
			for(i in code) {

				key = code[i].cdId;

				var detailArray = [];
				
				for(var cd in codeDetailTemp[key]) {
					detailArray.push(codeDetailTemp[key][cd]);
				}

				detailArray.sort(function(a, b) {
					if(a.sortOrd < b.sortOrd) {
						return -1;
					} else if(a.sortOrd > b.sortOrd) {
						return 1;
					} else {
						return 0;
					}
				});

				commonCode.codeDetail[key] = detailArray;
			}

			setLocalStorage('common_code', state.commonCode);
		},

		history(state, data) {
			// console.log('$store.history commit', data);
			if(data && data.from && data.to){
				var from = data.from;
				var to	 = data.to;

				//history에 저장하는 파라미터 정보와 실제 전달할 파라미터 정보를 분리하기 위해 deep copy를 실행한다.
				var toParam = JSON.parse(JSON.stringify(to.params));
				
				//caller가 이전페이지와 같을 경우 history에 이전페이지 파라미터 정보를 저장한다.
				var caller = toParam.caller;
				if(caller && caller.name == from.name){
					if(caller.params) delete caller.params.secrets;//secrets 값은 history에 저장하지 않는다.
					state.history[from.fullPath] = {params : caller.params};
					delete caller.params;
				}
				
				//history에 다음 페이지의 파라미터 정보를  저장한다.
				delete toParam.secrets;//secrets 값은 history에 저장하지 않는다.
				state.history[to.fullPath] = { params : toParam };
				
				//params에 secrets 정보는 저장하지 않는다.
				setSessionStorage('history', state.history);
			}
			
		},

		ncsLevelCode(state, data) {
			// console.log('$store.ncsLevelCode', data);
			state.ncsLevelCode = data;
			setSessionStorage('ncs_level_code', state.ncsLevelCode);
		},

		ncsDetailClassCode(state, data) {
			// console.log('$store.ncsDetailClassCode', data);
			state.ncsDetailClassCode = data;
			setSessionStorage('ncs_detail_class_code', state.ncsDetailClassCode);
		},

		licenseCode(state, data) {
			// console.log('$store.licenseCode', data);
			state.licenseCode = data;
			setSessionStorage('license_Code', state.licenseCode);
		},

	},
	actions: {
		history(context, data){
			// console.log('$store.history actions', data, context.state.history);
			if(data.from && data.to){
				var from = data.from;
				var to	 = data.to;
				var isBack	 = data.isBack;
				if(Object.keys(to.params).length == 0 && (isBack || !from.name)){
					//파라미터가 없고, back로 왔거나 to 정보가 없을경우 history를 사용한다.
					return context.state.history[to.fullPath];
				} else {
					//route push로 페이지 이동 시 params 값을 history에 저장한다.
					context.commit('history', data);
					if(to.params.caller){
						//caller 의 파라미터 정보는 history 저장하는데만 사용하고 다음 페이지로 전달하지는 않는다.
						delete to.params.caller.params;
					} else {
						//caller 정보가 없을 경우 이전 페이지 정보를 전달한다.
						to.params.caller = { name : from.name }
					}

					return;
				}
			}
		},
		commonCode(context) {
			return new Promise((resolve, reject) => {
				var commonCode = context.state.commonCode;
				if (!commonCode.loaded || commonCode.loaded < new Date().add(-1, 'day').getTime()) {
					//loaded 값이 없거나 1일 전에 로드 되었다면 통신에서 결과를 가져온다.
					axios.get('/api/main/code/getCommonCode')
						.then(res => {
							context.commit('commonCode', res.data);
							resolve(commonCode.codeDetail);
						})
						.catch(err => {
							reject(err)
						});
				} else {
					resolve(commonCode.codeDetail);
				}
			});
		},

		ncsLevelCode(context) {
			return new Promise((resolve, reject) => {
				var ncsLevelCode = context.state.ncsLevelCode;
				if (Object.keys(ncsLevelCode).length === 0) {
					//세션에 ncsLevelCode가 없다면
					axios.get('/api/code/getNcsLevelCode')
						.then(res => {
							context.commit('ncsLevelCode', res.data);
							resolve(ncsLevelCode);
						})
						.catch(err => {
							reject(err)
						});
				} else {
					resolve(ncsLevelCode);
				}
			});
		},

		ncsDetailClassCode(context) {
			return new Promise((resolve, reject) => {
				var ncsDetailClassCode = context.state.ncsDetailClassCode;
				if (Object.keys(ncsDetailClassCode).length === 0) {
					//세션에 ncsLevelCode가 없다면
					axios.get('/api/code/getNcsDetailClassCode')
						.then(res => {
							context.commit('ncsDetailClassCode', res.data);
							resolve(ncsDetailClassCode);
						})
						.catch(err => {
							reject(err)
						});
				} else {
					resolve(ncsDetailClassCode);
				}
			});
		},

		licenseCode(context) {
			return new Promise((resolve, reject) => {
				var licenseCode = context.state.licenseCode;
				if (Object.keys(licenseCode).length === 0) {
					//세션에 ncsLevelCode가 없다면
					axios.get('/api/code/getLicenseCode')
						.then(res => {
							context.commit('licenseCode', res.data);
							resolve(licenseCode);
						})
						.catch(err => {
							reject(err)
						});
				} else {
					resolve(licenseCode);
				}
			});
		},
	},
	modules: {
	}
})

function setSessionStorage(key, value) {
	// console.log('setSessionStorage', key, value)
	if (value !== undefined) {
		if (value instanceof Date) { sessionStorage.setItem(key, value.getTime()); }
		if (value instanceof Object) { sessionStorage.setItem(key, JSON.stringify(value)); }
		else { sessionStorage.setItem(key, value); }
	} else {
		sessionStorage.removeItem(key);
	}
	return value;
}

function getSessionStorage(key, type, defVal) {
	var value = sessionStorage.getItem(key);
	// console.log('getSessionStorage', key, value)
	if (value && type) {
		if (type == 'int') return parseInt(value);
		if (type == 'float') return parseFloat(value);
		if (type == 'json') return JSON.parse(value);
		if (type == 'date') return new Date(parseInt(value));
	}
	return value || defVal;
}

function setLocalStorage(key, value) {
	if (value !== undefined) {
		if (value instanceof Date) { localStorage.setItem(key, value.getTime()); }
		if (value instanceof Object) { localStorage.setItem(key, JSON.stringify(value)); }
		else { localStorage.setItem(key, value); }
	} else {
		localStorage.removeItem(key);
	}
	return value;
}

function getLocalStorage(key, type, defVal) {
	var result = localStorage.getItem(key);
	if (result && type) {
		if (type == 'int') return parseInt(result);
		if (type == 'float') return parseFloat(result);
		if (type == 'json') return JSON.parse(result);
		if (type == 'date') return new Date(parseInt(result));
	}
	return result || defVal;
}