<template>
	<component :is="tagNm || 'div'" @scroll="handleScroll">
		<slot />
	</component>
</template>

<script>
/**
 * 스크롤 컴포넌트
 *      -> 스크롤 이벤트를 사용하기 위한 컴포넌트
 * 
 * 사용법)
 *      props   : 
 *                  tagNm : 컴포넌트의 태그 (default: div)
 * 					gap : Top, Bottom 구별 시 여유 값
 * 					winScroll : 윈도우 스크롤 여부 (addEventListener)
 * 					check : 스크롤 이벤트 감지 여부 (default:true)
 *      emit    :  
 * 					scroll : Scroll 이벤트
 * 					scrollTop : 스크롤이 Top에 들어갔을 때의 이벤트
 * 					scrollBottom : 스크롤이 Bottom에 들어갔을 때의 이벤트
 *
 * 예시)
 *      <scroll @scrollBottom="searchNext">content</scroll>
 */
export default {
	name: 'Scroll',
	props: {
		tagNm: String,
		gap: {type:Number, default:100},
		winScroll: Boolean,
		check:{ type:Boolean, default:true },
	},
	data(){
		return {
			addEvent : false,
			nowTop: true,
			nowBtm: true,
		}
	},
	mounted(){
		if(this.winScroll){
			this.addEvent = true;
			window.addEventListener('scroll', this.handleScroll);
			window.addEventListener('resize', this.handleScroll);
		}
	},
	unmounted() {
		if(this.addEvent){
			this.addEvent = false;
			window.removeEventListener('scroll', this.handleScroll);
			window.removeEventListener('resize', this.handleScroll);
		}
	},
	methods: {
		handleScroll(event){
			if(this.check !== false){
				this.$emit('scroll', event);
				
				if(this.isTop()){
					if(!this.nowTop) this.$emit('scrollTop', event);
					this.nowTop = true;
				} else {
					this.nowTop = false;
				}

				if(this.isBottom()){
					if(!this.nowBtm) this.$emit('scrollBottom', event);
					this.nowBtm = true;
				} else {
					this.nowBtm = false;
				}
			}
		},
		hasScroll(){
			var target = this.target || this.$el;
        	// console.log('hasScroll', target.clientHeight, target.offsetHeight);
			return target.clientHeight < target.offsetHeight - this.gap;
		},
		isTop(){
			var target = this.target || this.$el;
			return target.scrollTop < this.gap;
		},
		isBottom(){
			if(this.addEvent){
				var bound =  this.$el.getBoundingClientRect();
				return window.screen.height > bound.height + bound.top;
			} else {
				var target = this.$el;
				return (target.offsetHeight + target.scrollTop) >= (target.scrollHeight - this.gap);
			}
		},
	},
	computed : {
		target(){
			if(this.addEvent){
				return document.scrollingElement;
			} else {
				return this.$el;
			}
		}
		
	}
}
</script>