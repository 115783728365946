<template>
	<div id="footer" :class="type == 'left' ? 'foot_s' : 'foot'">
		<div class="copy-wrap">
			<div class="foot-link">
				<div class="foot-link-title">하이프로</div>
				<ul class="foot-link-list">
					<li><a target="_blank" href="https://www.pronsolution.com/company">회사소개</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('MAN002M01')">이용약관</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('MAN002M02')">개인정보 처리방침</a></li>
					<li v-if="this.$store.state.userInfo.mberDivCd != ''"><a href="javascript:void(0);" @click="sitePop()">사이트맵</a></li>
				</ul>
			</div>
			<div class="foot-link">
				<div class="foot-link-title"><a href="javascript:void(0);" @click="pageMove('FAQ001M01')">FAQ</a></div>
				<ul class="foot-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('FAQ001M01')">수요기업</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('FAQ002M01')">기술인재</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('FAQ003M01')">공급기업</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('FAQ004M01')">이용요금</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('FAQ005M01')">포인트 제도</a></li>
				</ul>
			</div>
			<div class="foot-link">
				<div class="foot-link-title"><a href="javascript:void(0);" @click="pageMove('BBS001M01')">뉴스센터</a></div>
            	<ul class="foot-link-list">
					<li><a href="javascript:void(0);" @click="pageMove('BBS002M01')">공지사항</a></li>
					<li><a href="javascript:void(0);" @click="pageMove('BBS003M01')">하이프로 뉴스</a></li>
				</ul>
			</div>
			<div class="foot-link">
				<div class="foot-link-title">고객센터</div>
				<p class="mb-3">02-780-3800 <br v-if="type == 'left'"/>(10:00 - 18:00, 공휴일 제외)</p>
				<p class="mb-5">highpro@pron.co.kr</p>
				<button type="button" class="btn-inquiry"  @click="pageMove('CTS001M01')">문의하기</button>
			</div>
		</div>
		<div class="foot-copy-wrap">
			<div class="foot-copy">
				<p class="mb-3" :class="type == 'left' ? 'lh' : ''">(주)프로엔솔루션 (대표이사 : 안충호) / 사업자등록번호 : 215-87-48650 <br v-if="type == 'left'"/><template v-else>/</template> (07238) 서울시 영등포구 국회대로 76길 18, 804호 (여의도동, 오성빌딩) <br v-if="type == 'left'"/><template v-else>/</template> 대표전화 : 02-780-3800</p>
				<p>Copyright ⓒ 2021 Pro&amp;Solution All rights reserved.</p>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * footer
 *      -> 레이아웃 footer.
 * 
 * 사용법)
 *      props   : 
 *                  type : Footer 타입 (base, left)
 *
 * 예시)
 *      <footer type="left"/>
 * 
 */
export default {
	props: { type : String },
	methods: {
		pageMove(name) {
			// console.log('name', name);
			this.$router.move(name);
		},

		sitePop () {
			this.$.popup('/man/htu/HTU001P02');
		},

	}
}
</script>
