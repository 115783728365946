<template>
	<component :is="tagName||'span'">
		<template v-for="(sp1, idx1) in text.split('\n')">
			<component v-if="lineTag" :key="idx1" :is="lineTag" :class="lineClass" :style="lineStyle">
				<template v-for="(sp2, idx2) in sp1.split(' ')"><span v-html="nbsp" v-if="idx2 != 0" :key="idx1 + '_' + idx2"/>{{sp2}}</template>
			</component>
			<template v-else>
				<br v-if="idx1 != 0" :key="idx1"/>
				<template v-for="(sp2, idx2) in sp1.split(' ')"><span v-html="nbsp" v-if="idx2 != 0" :key="idx1 + '_' + idx2"/>{{sp2}}</template>
			</template>
		</template>
	</component>
</template>
<script>
/**
 * 공백(&nbsp;) 컴포넌트
 * 
 * 사용법)
 *	  props   : 
 *				n : 공백 갯수
 * 예시)
 *	  <nbsp n="3"/>
 */
export default {
	props: {n: {default: '1'}, value:String, tagName:String, lineTag:String, lineClass:{}, lineStyle:{}},
	computed : {
		text(){
			if(this.value) return this.value;
			return ' ';
		},
		nbsp(){
			var nbsp = '&nbsp;';
			var result = nbsp;
			for(var i=1; i<this.n; i++){
				result += nbsp;
			}
			return result;
		}
	}
}
</script>