<template>
	<div v-show="opened" class="popup active" style="position:fixed;">
		<component ref="popup" :is="template" @close="close" :name="name" :param="param" :style="style" @updated="updated"/>
		<!-- button v-if="closeBtn" @click="close()" style="position:absolute; top : 5px; right: 5px;">닫기</button -->
	</div>
</template>

<script>
import PronError from '/src/assets/js/PronError'
export default {
	props: ['name', 'path', 'param', 'closeBtn'],
	data() {
		return {
			opened : false,
			left:undefined,
			top:undefined,
		}
	},
	created(){
		//console.log('PopupWrap', '===== created =====', this.$refs.popup);
		if(typeof this.path != 'string'){
			this.close(new PronError('FFF', 'popup path error!!'));
		}
	},
	updated(){
		//console.log('PopupWrap', '===== updated =====', this.$refs.popup);
		this.$emit('updated', this);
		if(!this.opened){
			this.opened = true;
		}

		//console.log('PopupWrap', '===== updated ===== nextTick', this.$refs.popup);
		if(this.$refs.popup && this.$refs.popup.$el){
			this.updated();
		}
	},
	mounted(){
		//console.log('PopupWrap', '===== mounted =====', this.$refs.popup);
		window.addEventListener('resize', this.updated);
	},
	beforeDestroy() {
		//console.log('PopupWrap', '===== beforeDestroy =====', this.$refs.popup);
		window.removeEventListener('resize', this.updated);
	},
	methods: {
		close(result){
			// console.log('close', this.name, result);
			this.$emit('close_popup', this.name, result);
		},
		updated(){
			var dif = 10;
			this.$nextTick(() => {
				const width = document.documentElement.clientWidth;
				const height = document.documentElement.clientHeight;
				if(this.$refs.popup && this.$refs.popup.$el){
					var left = (width  - this.$refs.popup.$el.clientWidth) / 2;
					var top  = (height - this.$refs.popup.$el.clientHeight) / 2;
					//console.log('popupWeb', this.left, left, this.top, top)
					if(Math.abs((this.left||0) - left) > dif || Math.abs((this.top||0) - top) > dif){
						this.left = left;
						this.top  = top;
					}
					//console.log('PopupWrap', '===== updated =====', this.$refs.popup, this.top, this.left);
				}
			})
		}
	},
	errorCaptured() {
		//console.error('PopupWrap', '===== errorCaptured =====', err, instance, info);
		//this.close(err);
		return false;
	},
	computed: {
		template() {
			return () => {
				return import('@/views' + this.path).catch(err => { 
					// console.log('PopupWrap', 'path', this.path);
					//console.error('PopupWrap', 'import path error', err);
					this.close(err);
				})
			};
		},
		style(){
			var ret = {};
			if(this.left !== undefined && this.top !== undefined){
				ret = { 
					//'top'  : this.top + 'px',
					//'left' : this.left + 'px', 
					'margin-top': this.top + 'px',
					'margin-left': this.left + 'px', 
					//'position': 'initial',
				 };
			}
			//console.log('PopupWrap', '===== style =====', this.top, this.left, ret);
			return ret;
		},
	}
};
</script>