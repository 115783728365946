<template>
	<div class="ft_box">
		<div class="tit">{{name}}</div><img src="/images/tec/prj/dropdown.png" alt="드롭다운" @click="filterHide()"/>
		
		<div :class="isFilterHide ? 'check-box hide' : 'check-box'">

			<div v-for="code in cdList" :key="code.cd" class="check_list">
				<input type="checkbox" 
					:name="cdId" 
					:id="cdId + '_' + code.cd" 
					:value="code.cd"
					@change="onChange"

					:checked="isDefaultChecked(code.cd)"

					ref="input"
				>
				<label :for="cdId + '_' + code.cd">{{code.cdNm}}</label>
			</div>

		</div>
	</div>
</template>

<script>

/**
 * 검색 필터
 * 		공토코드값으로 checkbox 선택하는 검색필터
 * 
 * 사용법)
 *      props   : 
 *                  name : 검색필터명
 * 					cdId : 공통코드 ID
 *
 * 예시)
 *      <SearchFilter cdId="PRO106" v-model="input.pro106" />
 * 
 */
export default {

	props: {
	    name 	: { type: String, default : ''},
		cdId	: { type: String, default: '' },
		value	: {},
		list	: {},
	},
	data() {
        return { 
			cdList : [] ,
			isFilterHide : false,
		}
	},
    mounted() {
       if(this.cdId){
			//공통코드 사용.
			this.$store.dispatch("commonCode").then((res) => {
				this.cdList = res[this.cdId];
			});
		} else if(Array.isArray(this.list)){
			this.cdList = this.list;
		} else if(typeof this.list == 'string'){
			var sp = this.list.split(',');
			this.cdList = [];
			for(var i in sp){
				var sp2 = sp[i].split(':');
				if(sp2.length >= 2){
					this.cdList.push({ cd: sp2[0], cdNm: sp2[1] });
				} else {
					this.cdList.push({ cd: sp2[0], cdNm: sp2[0] });
				}
			}
		}
    },
	methods : {

		filterHide() {
			this.isFilterHide = !this.isFilterHide;
		},

       	onChange(event) {

			var val = event.target.value;
			var result = [];			
			
			result = this.val;

			if(event.target.checked){
				result.push(val);
				result.sort();
			} else {
				var idx = result.indexOf(val);
				if(idx >= 0){
					result.splice(idx, 1)
				}
			}

			// console.log(this.result);
			this.$emit('change', event, result);
			this.$emit('input' , result);
       	},

		isDefaultChecked(cd) {
			var val = this.val;
			if(val != undefined && val.indexOf(cd) > -1) {
				return true;
			}

			return false;
		}
	},

	computed : {
		val() {			
			return this.value || [];
		}
	}
}
</script>