import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '/src/store'
//import axios from 'axios'
import common from '/src/assets/js/common'

var MENU = {};

VueRouter.prototype.move = function(page, param){
	var refreshYn = param === false;
	//console.log('router move', page, param, refreshYn)
	if(typeof page == 'object'){
		var menu = MENU[page.menuId];
		if(!menu) return false;
		while(!menu.srcPath && menu.child  && menu.child.length > 0) menu = menu.child[0];
		//console.log('router move', page, param, refreshYn, menu);
		if(refreshYn) {
			return location.href = menu.urlPath;
		} else if(location.pathname == menu.urlPath) {
			return false;
		} else {
			return this.push({name : menu.menuId, param : param}).catch(() => alert('서비스 준비중입니다.'));
		}
	} else if(typeof page == 'string'){
		if(page == 'HOME'){
			return this.move(MENU[location.pathname.startsWith('/admin') ? 'ADMIN_HOME' : 'MENU_HOME'], param||false);
		} else if(page == 'LOGIN'){
			return this.move(MENU[location.pathname.startsWith('/admin') ? 'ADMIN_LOGN' : 'MENU_LOGN'], param);
		} else if(!page.startsWith('/')){
			return this.move(MENU[page], param);
		} else if(refreshYn) {
			return location.href = page;
		} else if(location.pathname == page) {
			return false;
		} else {
			return this.push({path : page, param : param}).catch(() => alert('서비스 준비중입니다.'));
		}
	}
};
VueRouter.prototype.getMenus = function(){ 
	var result = {};
	if(MENU['MENU']) {
		for(var i in MENU['MENU'].child){
			result[MENU['MENU'].child[i].menuId] = MENU['MENU'].child[i].child;
		}
	}
	//console.log('getMenus', MENU, result)
	return result;
}
VueRouter.prototype.getMenu = function(menuId){ 
	if(MENU[menuId]) {
		return MENU[menuId];
	}
	return {};
}
Vue.use(VueRouter)

var router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes : [],
	scrollBehavior (to, from, savedPosition) {
		// console.log('scrollBehavior', savedPosition, to, from);
	if (savedPosition) {
		return savedPosition
	} else {
		return { x: 0, y: 0 }
	}
	}
})

window.popStateDetected = false
window.addEventListener('popstate', () => {
	window.popStateDetected = true
})
router.beforeEach(function (to, from, next) {
//router.beforeResolve(function (to, from, next) {	
	// to : 이동할 url
	// from : 현재 url
	// next : to에서 지정한 url로 이동하기 위해 꼭 호출해야 하는 함수
	var isBack = window.popStateDetected;
	window.popStateDetected = false;

	//debugger
	if(router.getRoutes().length == 0){
		//console.log('========== router.beforeEach getRoutes ==========', '\nfrom', from, '\nto', to);
		_routes(function(){
			// next(to.path);
			next({ path: to.fullPath, replace: true});
		});

		
	} else {
		//console.debug('========== router.beforeEach check history ==========', '\nfrom', from, '\nto', to);
		store.dispatch('history', {from, to, isBack}).then(history => {
			//console.debug('========== router.beforeEach ==========\nhistory', history);
			if(history && history.params){
				for(var k in history.params){
					to.params[k] = history.params[k];
				}
			}
			next()
		})
	}
});

//router.afterEach(function () {
//	window.scroll(0, 0);
//});

function _routes(callback){
	if(store.state.userInfo.mberSeq != '' && location.pathname == '/MEM001M01') {
		common.logout();
	}
	common.http('/api/main/menu/getMenu')
	.then(res => {
		 // console.log('_routes', res)
		var menu = res.data.menu;

		if(res.data.profile != undefined && res.data.profile != '') {
			store.commit('profile', res.data.profile);	
		}

		MENU = {};
		for(var i in menu){
			var menuId = menu[i].menuId;
			var parentId = menu[i].parentMenuId;

			//MENU MAP에 추가
			if(MENU[menuId]){
				menu[i].child = MENU[menuId].child;
				MENU[menuId].parentMenuId.push(menu.parentMenuId);
				menu[i].parentMenuId = MENU[menuId].parentMenuId;
			} else {
				menu[i].child = [];
				menu[i].parentMenuId = [menu[i].parentMenuId];
			}
			MENU[menuId] = menu[i];
			
			//parent 에 child로 추가
			if(!MENU[parentId]){
				MENU[parentId] = { parentMenuId : [], child : [] }
			}
			MENU[parentId].child.push(menu[i]);
		}

		//console.log('route', 'menuInfo', MENU);
		//Route 추가
		for(var j in MENU){
			if(MENU[j].urlPath){
				router.addRoute(_route(MENU[j]));
			}
		}
		_routesComm();

		if(MENU['MENU']) {
			var result = {};
			for(var k in MENU['MENU'].child){
				result[MENU['MENU'].child[k].menuId] = MENU['MENU'].child[k].child;
			}
			store.commit('menu', result);
		}

		if(typeof callback == 'function'){
			callback(menu);
		}
	})
	.catch(() => {
		//console.log(err)
	});
}

function _routesComm(){
	var commRoute = [
		{viewId:'LGNTIMEPOP', urlPath: '/common/sessionTime', srcPath:'/common/sessionTime' },
		{viewId:'ERR0404', urlPath: '*', srcPath:'/common/error404' },
	];
	for(var i in commRoute){
		router.addRoute(_route(commRoute[i]));
	}
	//router.addRoute({path:'*', redirect:'/error/404'});
}

function _route(menu){
	var path = menu.srcPath;
	if(!path){
		if(store.state.userInfo.mberSeq){
			path = '/common/error403';
		} else if(menu.urlPath.startsWith('/admin')){
			//관리자 로그인(401 에러)
			return { path: menu.urlPath, name: menu.menuId, meta: menu, redirect: { name: 'MEM901M01' } };
		} else {
			//서비스 로그인(401 에러)
			return { path: menu.urlPath, name: menu.menuId, meta: menu, redirect: { name: 'MEM001M01' } };
		}
	}
	return { path: menu.urlPath, name: menu.menuId, meta: menu, component: () => import('../views' + path + '.vue') };
}

export default router