<template>
    <scroll class="screen_btn" :winScroll="true" ref="winScroll" v-show="hasScroll" @scroll="hasScroll = true">
        <img src="/images/admin/screen-top_btn.png" alt="탑버튼"    @click="quickMenuMove(true)"/>
        <div v-if="quickMenuInfo && quickMenuInfo.length > 0">
            <div id="quick-menu-ctl" class="quick-menu" v-if="!isOpen">
                <span @click="quickMenuOpen(true)" class="op">바로가기 열기</span>			
            </div>
            <div id="quick-menu" class="quick-menu-v" v-if="isOpen">	
                <div v-for="menu in quickMenuInfo" :key="menu.menuRef">
                    <button @click="quickMenuMove(menu.menuRef)">{{menu.menuName}}</button><br/>
                </div>
                <span @click="quickMenuOpen(false)" class="cls">바로가기 닫기</span>
            </div>
        </div>
        <img src="/images/admin/screen-down_btn.png" alt="다운버튼" @click="quickMenuMove(false)"/>
    </scroll>
</template>

<script>
/**
 * 화면내에서 바로가기 메뉴
 *      -> 화면내에서 바로가기를 위한 메뉴를 노출하고 메뉴 클릭시 해당 위치로 이동
 *         부모 컴포넌트에는 바로가기로 이동할 부분에 ref 설정 필요
 * 
 * 사용법)
 *      props   : 
 *                  isOpenProps  : 디폴트로 바로가기 메뉴를 열린 상태로 할지 여부
 *                  quickMenuInfo : 바로가기 메뉴 정보 (메뉴명, ref명)
 *      emit    :  
 *                  없음
 * 
 * 예시)
 *      <quickMenuComp :isOpenProps="false" :quickMenuInfo="quickMenuInfo" />
 * 
 */
import scroll from "@/components/Scroll.vue";
export default {
    components:{ scroll },
    props: {
        // ex)  quickMenuInfo : [
        //          { 
        //              menuName : '인적사항',
        //              menuRef  : 'ref_1'
        //          },
        //          { 
        //              menuName : '근무조건',
        //              menuRef  : 'ref_2'
        //          }
        //      ]
        quickMenuInfo : { type : Array, default : () => { } },
        isOpenProps : { type : Boolean, default : false },
    },
    data() {
        return { 
            isOpen : false,
            hasScroll: true,
        }
    },
    mounted() {
        // props로 받은 값을 컴포넌트 내부에서 변경할 수 없어서 별도 변수에 저장해서 사용
        // props는 단반향 바인딩이라서....
        this.isOpen = this.isOpenProps;
        this.hasScroll = this.$refs.winScroll.hasScroll();
    },
    methods : {
        quickMenuOpen(isOpen) {
            this.isOpen = isOpen;
        },
        quickMenuMove(refName) {
            var ref = undefined;
            if(typeof refName == 'string' && this.$parent.$refs[refName] instanceof Element){
                ref = this.$parent.$refs[refName];
            } else if(refName instanceof Element){
                ref = refName;
            } else {
                //ref 객체가 없을 경우 문서 상/하단으로 스크롤 한다.
                ref = document.scrollingElement;
                ref.scroll({ top: refName === false ? ref.scrollHeight: 0, behavior: 'smooth' });
                return;
            }

            if(ref instanceof Element){
                ref.scrollIntoView({behavior : 'smooth'});
            }
        },
    }
    
}
</script>