<template>
	<div id="wrap" @click="clickEvent">
		<div class="head-banr" v-if="topBnrInfo">
			<div :class="topBnrInfo.bannerLinkDivCd != '00'? 'inner btn_cursor' : 'inner'">
				<!-- <div class="banr-txt">차별화된 IT 엔터테인먼트 플랫폼, <strong>하이프로</strong></div>
				<div class="banr-btn">
					<a href="javascript:void(0);"><strong>동급 최고수준의 보상</strong> 받아가세요!</a>
				</div> -->
				<!-- <img src="/images/top_bnr.png"> -->
				<ImageComp :src="'/api/main/bnr/image?bannerSeq='+topBnrInfo.bannerSeq" alt="최상단배너 이미지"  @click="$router.push(topBnrInfo.bannerLinkUrl)"/>
			</div>
		</div>
		<div class="head-wrap" :class="{ prf : $store.state.userInfo.mberSeq}" >
			<div class="gnb">
				<div style="position: relative; display: flex;">
					<div class="logo">
						<a class="link-logo" :class="{ pmg : $store.state.menu.MENU_0001.length == 5}" href="/">
							<span v-if="$store.state.profile.name == '로컬'" style="color:red; position: absolute;">{{$store.state.profile.name}}</span>
							<img src="/images/logo.png"/>						
							<!-- 원본 - 개발서버 글씨 지우기 -->
							<!-- <span v-if="$store.state.profile.name" style="color:red">{{$store.state.profile.name}}</span> -->							
						</a>
					</div>
					<!--검색창-->
					<div class="head-search">
						<input type="text" placeholder="어떤 프로젝트를 찾으세요?" v-model="searchKeyword" @keyup.enter="movePrjList()">
						<img src="/images/icon_srch.png" @click="movePrjList()">
						<!-- <InputComp type="text" placeholder="어떤 프로젝트를 찾으세요?" v-model="input.searchKeyword" @keyup.enter="getList()"  />
						<img src="/images/icon_srch.png" @click="getList()"> -->
					</div>
					<div class="pron_ci">
						<a target="_blank" href="https://www.pronsolution.com/company"><img src="/images/pron_ci.png"></a>
					</div>
				</div>
				<!--
				<div id="nav">
					<ul class="list-gnb" :class="{ prf : $store.state.menu.MENU_0001.length == 3, pmg : $store.state.menu.MENU_0001.length == 5}">
						<template v-for="depth1 in $store.state.menu.MENU_0001">
							<li :key="depth1.menuId" v-if="depth1.menuDivCd != '99'">
								<a class="link-gnb" href="javascript:void(0)" @click="move(depth1)">{{depth1.menuNm}}</a>
							</li>
						</template>
					</ul>
				</div>
				-->
				<div style="position: relative;display: flex;width: 1200px; margin-top: 14px;">
					<div class="all-menu" >
						<div id="all-menu-button">
							<img id="all-menu-button" v-if="menuStat == 'none'" src="/images/menu_open.png" alt="전체메뉴 열기" >
							<img id="all-menu-button" v-else src="/images/menu_close.png" alt="전체메뉴 닫기" >
						</div>
					</div>
					<div id="nav">
						<ul class="list-gnb" :class="{ prf : $store.state.menu.MENU_0001.length == 3, pmg : $store.state.menu.MENU_0001.length == 5}">
							<template v-for="depth1 in $store.state.menu.MENU_0001">
								<li :key="depth1.menuId" v-if="depth1.menuDivCd != '99'">
									<a class="link-gnb" id="prjMng-all-menu-button" href="javascript:void(0)" @click="move(depth1)">{{depth1.menuNm}}</a>
								</li>
							</template>
						</ul>
					</div>
					<div class="gnb-login" :class="{ prf : $store.state.userInfo.mberSeq}">
						<div class="list-login" :class="{ prf : $store.state.userInfo.mberSeq, myp: $store.state.userInfo.mberSeq && $store.state.menu.MENU_0001.length == 4 }">
												
							<template v-for="depth1 in $store.state.menu.MENU_LOGN">
								<div class='btn-login' :key="depth1.menuId" v-if="depth1.menuDivCd != '99'" @click="move(depth1)">
									{{depth1.menuNm}}
								</div>
							</template>						
							<template v-for="depth1 in $store.state.menu.MENU_JOIN">
								<div class='btn-cs' :key="depth1.menuId" v-if="depth1.menuDivCd != '99'" @click="move(depth1)">
									{{depth1.menuNm}}
								</div>
							</template>
							
							<template v-for="depth1 in $store.state.menu.MENU_ALRM">	
								<!-- <div class='btn-myinfo' :key="depth1.menuId" v-if="depth1.menuDivCd != '99'" @click="move(depth1)" :class="{pmg : $store.state.userInfo.mberDivCd == '22'}">
									<img src="/images/icon_profile.png" :alt="depth1.menuNm" style="margin-right:5px">
									<a href="javascript:void(0)">이력서 관리</a>
								</div>		 -->
								
								<!-- <div class='btn-myinfo' :key="depth1.menuId" v-if="depth1.menuDivCd != '99'" @click="move(depth1)" :class="{pmg : $store.state.userInfo.mberDivCd == '22'}">
									<img src="/images/icon_mic.png" :alt="depth1.menuNm" style="margin-right:5px">
									<a href="javascript:void(0)">인터뷰 현황</a>
								</div> -->
								
								<!-- <div class='btn-myinfo' :key="depth1.menuId" v-if="positionName == '프로젝트담당' || positionName == '공급기업담당'" :click="positionName == '프로젝트담당'? move('/PRJ306M01'):move('/PRJ404M01')"> -->
								<div class='btn-myinfo' :key="depth1.menuId" v-if="positionName == '프로젝트담당' || positionName == '공급기업담당'" @click="moveInterview()">	
									<img src="/images/icon_mic.png" style="margin-right:5px">
									<a>인터뷰 현황</a>
								</div>
												
								<div class='btn-notice' :key="depth1.menuId" v-if="depth1.menuDivCd != '99'" @click="move(depth1)" :class="{pmg : $store.state.userInfo.mberDivCd == '22'}">
									<img src="/images/icon_bell.png" :alt="depth1.menuNm">
									<span class="notice" v-if="$store.state.userInfo.notiNewCnt > 0">{{$store.state.userInfo.notiNewCnt}}</span>
								</div>
							</template>
							<div class="dropdown" v-if="$store.state.userInfo.mberSeq">
								<div class="btn-mypage" :class="{myp: $store.state.userInfo.mberSeq && $store.state.menu.MENU_0001.length == 4}"><img style="margin-right:5px" src="/images/icon_member.png" alt="마이페이지"><div id="id_info">{{positionName}} {{$store.state.userInfo.mberNm}}</div></div>
								<div class="dropdown-content" :class="{myp: $store.state.userInfo.mberSeq && $store.state.menu.MENU_0001.length == 4,myp2:$store.state.userInfo.mberDivCd == '22'}">
									<template v-for="depth1 in $store.state.menu.MENU_MYPG">
										<a :key="depth1.menuId" v-if="depth1.menuDivCd != '99' && depth1.menuId != 'LOGOUT'" href="javascript:void(0)" @click="move(depth1)"><img :src="depth1.menuImgUrl||'/images/icon_member.png'" :alt="depth1.menuNm">{{depth1.menuNm}}</a>
										<a :key="depth1.menuId" v-if="depth1.menuId == 'LOGOUT'" href="javascript:void(0)" @click="$.logout()"><img src="/images/tec/prf/sub_mypage07.png" :alt="depth1.menuNm">{{depth1.menuNm}}</a>
									</template>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- <div class="sub-head-wrap" v-if="$store.state.menu.MENU_0002.length > 0" :class="{pmg : $store.state.userInfo.mberDivCd == '22', sco : $store.state.userInfo.mberDivCd == '31'}">
				<ul>
					<template v-for="depth1 in $store.state.menu.MENU_0002">
						<li :key="depth1.menuId" v-if="depth1.menuDivCd != '99'">
							<a class="link-gnb" href="javascript:void(0)" @click="move(depth1)">{{depth1.menuNm}}</a>
						</li>
					</template>
				</ul>
			</div> -->

			<div id="all-menu-view" :style="{ display:menuStat }">
				<div class="menu-wrap" id="all-menu-area">		
					<!--기술인재 로그인시-->
					<template v-for="depth1 in $store.state.menu.MENU_0002" >
						<div :key="depth1.menuId" v-if="depth1.menuDivCd != '99'" class="menu-item" id="all-menu-area">
							<span class="tit" id="all-menu-area">{{depth1.menuNm}}</span>
							<ul>
								<template v-for="depth2 in depth1.child">
									<li :key="depth2.menuId" v-if="depth2.menuDivCd != '99'" @click="move(depth2)">
										<a href="javascript:void(0)">{{depth2.menuNm}}</a>
									</li>
								</template>
							</ul>
						</div>
					</template>

					<!-- <div class="menu-item">
						<span class="tit">프로젝트 정보</span>
						<ul>
							<li><a href="javascript:void(0)">프로젝트 찾기</a></li>
						</ul>
					</div>
					<div class="menu-item">
						<span class="tit">내 프로젝트</span>
						<ul>
							<li><a href="javascript:void(0)">제안단계 프로젝트</a></li>
							<li><a href="javascript:void(0)">참여예정· 참여중 프로젝트</a></li>
							<li><a href="javascript:void(0)">완료 프로젝트</a></li>
							<li><a href="javascript:void(0)">프로젝트 인터뷰 현황</a></li>
							<li><a href="javascript:void(0)">관심/지원 프로젝트</a></li>
						</ul>
					</div>
					<div class="menu-item">
						<span class="tit">적립 포인트</span>
						<ul>
							<li><a href="javascript:void(0)">내 적립포인트</a></li>
						</ul>
					</div>					 -->

					<!--수요기업관리총괄 로그인시
					<div class="menu-item">
						<span class="tit">공급기업 프로젝트</span>
						<ul>
							<li><a href="javascript:void(0)">전체 프로젝트</a></li>
							<li><a href="javascript:void(0)">진행단계별 프로젝트</a></li>
							<li><a href="javascript:void(0)">소속인재 프로젝트 참여 현황</a></li>
						</ul>
					</div>
					<div class="menu-item">
						<span class="tit">일반 프로젝트</span>
						<ul>
							<li><a href="javascript:void(0)">전체 프로젝트</a></li>
							<li><a href="javascript:void(0)">진행단계별 프로젝트</a></li>
							<li><a href="javascript:void(0)">소속인재 프로젝트 참여 현황</a></li>
						</ul>
					</div>
					<div class="menu-item">
						<span class="tit">기술인재 정보</span>
						<ul>
							<li><a href="javascript:void(0)">소속 인재 현황</a></li>
						</ul>
					</div>
					<div class="menu-item">
						<span class="tit">적립 포인트</span>
						<ul>
							<li><a href="javascript:void(0)">내 적립포인트</a></li>
						</ul>
					</div>
					-->

					<!--수요기업프로젝트담당 로그인시
					<div class="menu-item">
						<span class="tit">공급기업 프로젝트</span>
						<ul>
							<li><a href="javascript:void(0)">프로젝트 등록</a></li>
							<li><a href="javascript:void(0)">전체 프로젝트</a></li>
							<li><a href="javascript:void(0)">진행단계별 프로젝트</a></li>
							<li><a href="javascript:void(0)">소속인재 프로젝트 참여 현황</a></li>
						</ul>
					</div>
					<div class="menu-item">
						<span class="tit">일반 프로젝트</span>
						<ul>
							<li><a href="javascript:void(0)">프로젝트 등록</a></li>
							<li><a href="javascript:void(0)">진행단계별 프로젝트</a></li>
							<li><a href="javascript:void(0)">소속인재 프로젝트 참여 현황</a></li>
							<li><a href="javascript:void(0)">프로젝트 지원인재</a></li>
							<li><a href="javascript:void(0)">프로젝트 인터뷰요청</a></li>
						</ul>
					</div>
					<div class="menu-item">
						<span class="tit">기술인재 정보</span>
						<ul>
							<li><a href="javascript:void(0)">기술인재 찾기</a></li>
							<li><a href="javascript:void(0)">소속 인재 현황</a></li>
							<li><a href="javascript:void(0)">관심 인재</a></li>
						</ul>
					</div>
					-->

					<!--공급기업 로그인시
					<div class="menu-item">
						<span class="tit">공급기업 프로젝트</span>
						<ul>
							<li><a href="javascript:void(0)">전체 프로젝트</a></li>
							<li><a href="javascript:void(0)">진행단계별 프로젝트</a></li>
							<li><a href="javascript:void(0)">소속인재 프로젝트 참여 현황</a></li>
						</ul>
					</div>
					<div class="menu-item">
						<span class="tit">일반 프로젝트</span>
						<ul>
							<li><a href="javascript:void(0)">프로젝트 찾기</a></li>
							<li><a href="javascript:void(0)">소속인재 프로젝트 지원 현황</a></li>
							<li><a href="javascript:void(0)">소속인재 프로젝트 인터뷰 현황</a></li>
							<li><a href="javascript:void(0)">소속인재 프로젝트 참여 현황</a></li>
						</ul>
					</div>
					<div class="menu-item">
						<span class="tit">기술인재 정보</span>
						<ul>
							<li><a href="javascript:void(0)">소속 인재 현황</a></li>
						</ul>
					</div>
					-->
				</div>
			</div>
		</div>
		
		<!-- <div class="sub-head-wrap" v-if="$store.state.menu.MENU_0002.length > 0" :class="{pmg : $store.state.userInfo.mberDivCd == '22', sco : $store.state.userInfo.mberDivCd == '31'}">
            <ul>
				<template v-for="depth1 in $store.state.menu.MENU_0002">
					<li :key="depth1.menuId" v-if="depth1.menuDivCd != '99'">
						<a class="link-gnb" href="javascript:void(0)" @click="move(depth1)">{{depth1.menuNm}}</a>
					</li>
				</template>
            </ul>
        </div> -->

		<router-view @setViewConfig="setViewConfig" @clearViewConfig="clearViewConfig" :key="$route.fullPath" @destroyKeyword="destroyKeyword" @submit="noneMenu()"></router-view>

		<footer-comp v-if="!viewConfig.footer" />

		<quick-menu-comp v-if="viewConfig.quickMenu !== false"/>

		<div class="popupWrap">
			<template v-for="(popup, index) in popupStack">
				<popup-wrap :key="popup.name" :name="popup.name" :path="popup.path" :param="popup.param" :closeBtn="popup.closeBtn" @updated="updatedPopup" @close_popup="closePopup" :style="{ 'z-index': 10000 + index * 100 }"/>
			</template>
			<template v-if="sessionTime.alert">
				<popup-wrap name="sessionTime" path="/common/sessionTime" :closeBtn="false" :param="sessionTime" @close_popup="closeSessionTime" :style="{ 'z-index': 90000 }"/>
				<!-- style="width: 509px; height: 448px; top: calc(50% - 190px); left: calc(50% - 220px)"-->
			</template>
			<template v-if="$store.state.loading">
				<popup-wrap name="loadingbar" path="/common/loading" :closeBtn="false" :style="{ 'z-index': 99999 }"/>
			</template>
		</div>
	</div>
</template>
<script>
import popupWrap from "@/components/common/PopupWrap";
import footerComp from "@/components/layout/footer";
import quickMenuComp from "@/components/QuickMenuComp.vue";

export default {
	name: "App",
	metaInfo: {
		meta: [
			{
			vmid: 'description',
			name: 'description',
			content:
				'프로엔솔루션의 하이프로는 검증된 금융IT인재 매칭 플랫폼입니다. IT 외주 프로젝트와 IT 개발 인재를 확인할 수 있습니다.', 
			},
			{
			vmid: 'keywords',
			name: 'keywords',
			content: 'IT엔터테인먼트, 금융IT인재 매칭 플랫폼, highpro, 하이프로, 프로엔, 프로엔솔루션, 금융IT인재, 금융IT개발, 금융개발, 금융IT프로젝트, 금융프로젝트, 금융IT취업, 프리랜서, 프리랜서사이트, 프리랜서개발자, 외주, 개발자, 개발자외주, 개발자프리랜서, 프로그램개발, 웹개발자, 앱개발, 어플개발, 어플리케이션, 금융업무, 전문인력, 전문가, IT인재관리, 인재관리',
			},
			// {
			//   vmid: 'author',
			//   name: 'author',
			//   content: 'pozafly',
			// },
		]
	},
	components: {
		popupWrap, footerComp, quickMenuComp
	},
	data() {
		return {
			sessionTime : {
				expiredTime : this.$store.state.sessionTime.timeout,
				alert : false,
				closeToken : '',
			},
			viewConfig: {},
			popupStack: [],
			menuStat : 'none',

			topBnrInfo: {},
			searchKeyword: '',

			pageId : this.$route.name,
		};
	},
	watch:{
    //     $route() {
	// 		if(this.$store.state.userInfo.mberSeq != "" &&this.$route.fullPath == "/MEM001M01"){
	// 			this.$.logout();
	// 		}
    //     },
		
    },
	
	beforeMount() {
		window.addEventListener("beforeunload", event => {
			//console.log('beforeunload', this.$route)
			if(this.$route.meta.refreshDetectYn == 'Y'){
				event.preventDefault();
            	event.returnValue = "";
			}
        })

    },

	mounted() {
		// 최상단 배너 조회
		this.$.httpPost("/api/main/topBnrInfo")
            .then(res => {
                this.topBnrInfo = res.data.topBnrInfo

            }).catch(this.$.httpErrorCommon);

		this.$.loading(false);
		this.$.onEvent("popup", (path, param, opts, closeBtn, callback) => {
			this.openPopup(path, param, opts, closeBtn, callback);
		});
		
		var checkTime = new Date().getTime();
		var checkMinute = 1; //1분
		setInterval(()=>{
			var now = new Date().getTime();
			var timeout = this.$store.state.sessionTime.timeout;
			var token = this.$store.state.userToken.token;
			var token_start = this.$store.state.userToken.token_start;
			//console.debug('sessionTime', 'check', token);
			if(token){
				if(checkTime < now){
					checkTime = new Date().add(checkMinute, 'MINUTE');
					this.$.getNoti();
				}
				this.sessionTime.expiredTime = timeout - (now - token_start);
				//console.debug('sessionTime', 'check2', this.sessionTime.expiredTime);
				if(this.sessionTime.expiredTime <= 0){
					// 세션 남은시간이 0 보다 작을때 (로그아웃)
					this.sessionTime.alert = true;
					this.$.logout(false);
					//console.debug('sessionTime', 'logout', this.sessionTime.expiredTime, this.sessionTime.alert, this.sessionTime.closeToken != '');
				} else if(token == this.sessionTime.closeToken){
					// 로그아웃 안내 닫기버튼을 클릭했을 경우
					this.sessionTime.alert = false;
				} else if(this.sessionTime.expiredTime < this.$store.state.sessionTime.beforeAlert){
					// 세션 남은시간이 알럿시간보다 작을 경우
					this.sessionTime.alert = true;
					//console.debug('sessionTime', 'updated', this.sessionTime.expiredTime, this.sessionTime.alert, this.sessionTime.closeToken != '');
				} else {
					this.sessionTime.alert = false;
				}
			}
		}, 400);
	},

	// beforeUpdate(){
	// 	// console.log("%%% window.location.pathname: " + window.location.pathname);
	// 	// console.log("%%% this.$route.fullPath : " + this.$route.fullPath);
	// 	// console.log("%%% this.$store.state.userInfo.mberSeq : " + this.$store.state.userInfo.mberSeq);
	// 	if(window.location.pathname == "/MEM001M01" && this.$route.fullPath == "/" && this.$store.state.userInfo.mberSeq != ""){
	// 		this.$.logout('/');
	// 	}
	// },

	errorCaptured() {
		//console.log('PopupWrap', '===== App =====', err, instance, info);
		return false;
	},
	methods: {
		clickEvent(event) {
			if(event.target.id == "all-menu-button" || (event.target.id == "prjMng-all-menu-button" && event.target.innerText == "프로젝트 관리")) {
				this.showMenu();	
			} else {
				if(this.menuStat == "block") {
					if(event.target.id != "all-menu-area" && event.target.id != "all-menu-view") {
						this.menuStat = "none";
					}
				}
				
			}
			
		}
		,
		setViewConfig(viewConfig){
			//console.debug('App', 'setViewConfig', this.$route.name, viewConfig);
			for(var key in viewConfig){
				this.viewConfig[key] = viewConfig[key];
			}
		},
		clearViewConfig(){
			//console.debug('App', 'clearViewConfig', this.$route.name, viewConfig)
			this.viewConfig = {}
		},
		destroyKeyword() {
			if(this.$route.name == "MAN001M03") {
				this.searchKeyword = this.$route.query.keyword;
			}else{
				this.searchKeyword = '';
			}
		},
		openPopup(path, param, opts, closeBtn, callback) {
			try {
				//console.log("openPopup", path, param, opts, closeBtn, callback);
				this.$.loading(true);

				opts = opts || {};
				var req = {
					name: "P" + new Date().getTime(),
					path : path,
					param: param||{},
					width: opts.width||400,
					height: opts.height||0,
					closeBtn: closeBtn !== false,
					callback : callback
				};
				this.popupStack.push(req);		
			} catch(err){
				callback(err);
			}
		},
		updatedPopup(popupVue) {
			var name = popupVue.name;
			//console.log("updatedPopup", name, popupVue);
			for (var i in this.popupStack) {
				if (this.popupStack[i].name == name) {
					var info = this.popupStack[i];
					//console.log("updatedPopup info", info, popupVue, this);
					if(info.height == 0){
						var height = popupVue.$el.clientHeight;
						var maxHeight = window.innerHeight - 40;
						info.height = (height < maxHeight) ? height : maxHeight;
					}
					this.$.loading(false);
					break;
				}
			}
		},
		closePopup(name, result) {
			//console.log("closePopup", name, result);
			for (var i in this.popupStack) {
				if (this.popupStack[i].name == name) {
					var info = this.popupStack[i];
					info.callback(result);
					this.popupStack.splice(i, 1);
					this.$.loading(false);
					break;
				}
			}
		},
		closeSessionTime(result){
			//console.log("sessionTime", "closeSessionTime", name, page)
			this.sessionTime.alert = false;
			if(result === false){
				this.$.logout('LOGIN');
			} else if(this.sessionTime.expiredTime <= 0){
				// 세션 남은시간이 0 보다 작을때 (로그아웃)
				this.$.logout();
			} else {
				this.sessionTime.closeToken = this.$store.state.userToken.token;
			}
		},
		login() {
			//this.$router.push('/MEM001M01');
			this.$router.move('LOGIN');	
		},
		move(menu){
			switch(menu.menuId){
				case 'HTU001P01' :
					this.$.popup('/man/htu/HTU001P01');
				break;
				case 'MAN999M99' :
					// this.showMenu();
				break;
				default:
					this.$router.move(menu);
			}
			
		},
		showMenu() {
			if(this.$store.state.userInfo.mberSeq == '') {
				this.$.popup('/tec/prj/PRJ101P03')
					.then(res => {
						if(res) {
							// 회원가입 화면으로 이동
							this.$router.push({name : 'MEM003M01'});
						}
					});
					return;
			} 
			
			if(this.menuStat == 'none') this.menuStat = 'block';
			else this.menuStat = 'none';
		},

		movePrjList(){
			this.$router.push({name:'MAN001M03', query :{ keyword : this.searchKeyword}});
		},

		moveInterview(){
			if(this.$store.state.userInfo.mberDivCd == '22'){
				this.$router.move('/PRJ306M01');
			}else{
				this.$router.move('/PRJ404M01');
			}
		}

	},

	computed : {
		positionName() {
			if(this.$store.state.userInfo.mberDivCd == '11') return "기술인재"
			if(this.$store.state.userInfo.mberDivCd == '21') return "관리총괄"
			if(this.$store.state.userInfo.mberDivCd == '22') return "프로젝트담당"
			if(this.$store.state.userInfo.mberDivCd == '31') return "공급기업담당"
			return false;	
		}
	}
};
</script>