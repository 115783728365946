<template>
    <img :src="imgSrc" @click="$emit('click', $event)" :style="{width:width, height:height}"/>
</template>

<script>
/**
 * 이미지 컴포넌트
 *      -> src 로 이미지 조회 후 에러시 defaultSrc 노출
 * 
 * 사용법)
 * @props width     : IMAGE 타입의 경우 width 지정
 * @props height    : IMAGE 타입의 경우 height 지정
 * @props src       : 다운로드 경로
 * @props defaultSrc: IMAGE 타입의 경우 디폴트 이미지 경로
 * 
 * @emit  click : 클릭이벤트
 * 
 * @예시
 *  <ImageComp :src="'/api/mem/prf/profile?techMberSeq=' + data.mberSeq" defaultSrc="PROFILE"/>
 */
export default {
    emits: ['imgYn'],
    props: {
        width: Number,
        height: Number,
        param: Object,
		src: String,
        defaultSrc: String,
   },
   data() {
		return {
            result : undefined,
            defMap : {
                'PHOTO'   : '/images/cstom_photo.png',
                'PROFILE' : '/images/profile_df01.png',
                'PROFILE_W' : '/images/profile_df01_w.png',
                'COMPANY' : '/images/profile_df02.png',
            },
        };
    },
    watch:{
        src(){
            this.getImage();
        }
    },
    created(){
        this.getImage();
    },
    methods : {
        getImage(){
            if(this.src.indexOf('http') == 0 || this.src.indexOf('/') == 0){
                //console.log('ImageComp', 'getImage', this.src)
                var options =  { responseType : 'blob' };
                this.$.http(this.src, this.param, options, 'GET', false).then(res => {
                        //console.log('ImageComp', 'getImage success', res)
                        if(res.data.size == 0){
                            this.result = false;
                        } else {
                            this.result = window.URL.createObjectURL(res.data);
                        }
                    }).catch(() => {
                        //console.log('ImageComp', 'getImage error')
                        this.result = false;
                    });
            } else if(this.src) {
                this.result = this.src;
            }
        },
        deleteImg() {
            this.$.httpPost('/api/mem/prf/deleteProfile', { mberSeq : this.$store.state.userInfo.mberSeq, mberDivCd : this.$store.state.userInfo.mberDivCd })
                .then(() => {
                    //console.log('PRF101M01', 'deleteProfile', res.data);
                    // this.result=false;
                    this.$nextTick(() => {
                        this.result=false;
                    });
                }).catch(this.$.httpErrorCommon);
        }
    },
	computed : {
        imgSrc(){
            var atchYn = false;
            if(this.result){
                atchYn = true;
                this.$emit('imgYn', atchYn);
                return this.result;
            }

            //기본이미지 조회
            if(!this.defaultSrc) return '';
            
            var defSrc = this.defMap[this.defaultSrc.toUpperCase()];
            if(defSrc) {
                this.$emit('imgYn', atchYn);
                return defSrc;
            }

            this.$emit('imgYn', atchYn);
            return this.defaultSrc;
        }
    }
}
</script>