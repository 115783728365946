<template>
	<component :is="tagName || 'div'" class="auto_complete" @focusout="focusout">
		<div class="search_input">
			<!--<input type="text" :value="val" @input="input" @focus="focus" @keydown="$emit('keydown', $event)" @keyup="$emit('keyup', $event)" :placeholder="placeholder||title" :class="classNm" :disabled="disabled" :readonly="readonly" ref="input"/> -->
			<InputComp :type="type" :value="val" :title="title" :placeholder="placeholder" :classNm="classNm" :disabled="disabled" :readonly="readonly"
				:maxlength="maxlength" :inputable="inputable" :showError="showError" :rules="rules" tagName="div"
				@input="input" @focus="focus" @keydown="$emit('keydown', $event)" @keyup="$emit('keyup', $event)" ref="input"/>
			<input type="image" src="/images/mem/icon_search.png" class="icon_search" disabled="disabled" v-show="!readonly && !disabled"/>
		</div>
		<div class="auto_complete_result" :class="{ show: (openList && !readonly && !disabled ) }">
			<div class="auto_complete_list">
				<template v-for="(item, index) in selectList">
					<div :key="index" v-show="Object.is(error, val) || itemText(item).toUpperCase().indexOf((val||'').toUpperCase()) >= 0" @click="clickAutoComplete(item, index)">
						<slot :item="item" :index="index">{{itemText(item)}}</slot>
					</div>
				</template>
				<div class="auto_complete_more" v-if="allowMore && !error && val" @click="clickAutoComplete()"><span></span>직접입력 &gt; '{{val}}'</div>
			</div>
		</div>
		<!--span class="error" v-if="error">{{error.message}}</span-->
	</component>
</template>

<script>
/**
 * Input 필드
 * 
 * 사용법)
 *	  props   : 
 *				  type  : format 적용을 위한 type
 *				  value : 입력값. v-model 로 모델 바인딩하여 사용
 *				  title : input title
 *				  format : format 적용 시 사용
 *	  emit	:  
 *				  input : input 값 변경이 일어날 때 작동 (v-model 로 모델 바인딩 시 양방향 데이터바인딩)
 * 
 * 예시)
 *	  <AutoComplete type="number" v-model="number" title="숫자"/>
 * 
 */
//import PronError from '/src/assets/js/PronError'

export default {

	props: {
		type	: { type   : String, default: 'text' },
		value	: { },
		title	: { type	: String },
		placeholder : { type : String },

		//start	: {},
		//end	: {},
		
		tagName	: { type: String },
		classNm	: { type : String },
		readonly	: { },
		disabled	: { },
		
		maxlength : { },
		inputable: { },
		showError : { type: Boolean, default : true },
		rules : { },
		
		list	: { type : Array },
		listText	: { type : String },
		listValue	: { type : String },
		cdId	: { type : String },
		url		: { type : String },
		param	: {},
		allowMore	: Boolean,
	},
	data() {
		var ret		 = {};
		ret.val		 = this.value;
		ret.focusIn	 = false;
		ret.openList = false;
		ret.searchSrc  = undefined;
		ret.searchList = undefined;
		//console.log('AutoComplete', 'data', ret)
		return ret;
	},
	watch: {
		value(){
			this.val = this.value;
		}
	},
    mounted() {
        //console.log('SelectComp', 'mounted', this.type, this.cdId, this.value);
		this.id = this.cdId + this.type.substr(0,1).toUpperCase() + this._uid;
		if(this.cdId){
			//공통코드 사용.
			this.searchSrc = this.cdId;
			this.$store.dispatch("commonCode").then((res) => {
				this.searchList = res[this.cdId];
			});
		}
    },
	methods : {
		itemText(item){
			//console.log('AutoComplete', 'showAutoComplete', this.listText, this.listValue, item);
			if(typeof item == 'string'){
				return item;
			} else if(typeof item == 'object' && item[this.listText]){
				return item[this.listText];
			}
			return "";
		},
		focus(){
			//console.log('AutoComplete', 'focus', event);
			this.focusIn = true;
			this.openList = true;
		},
		focusout(){
			//console.log('AutoComplete', 'focusout', document.activeElement);
			this.focusIn = false;
			setTimeout(()=>{
				//console.log('AutoComplete', 'focusout setTimeout', this.openList);
				if(this.openList){
					var temp = null;
					for(var i in this.selectList){
						var text = this.itemText(this.selectList[i]);
						if(typeof text == 'string' && text.indexOf(this.val) >= 0){
							if(temp != null){
								this.val = this.value;
								this.openList = false;
								return;
							} else {
								temp = i;
							}
						}
					}
					if(temp){
						this.clickAutoComplete(this.selectList[temp], temp)
					} else if(this.allowMore) {
						this.clickAutoComplete()
					} else {
						this.val = this.value;
					}
				}
				this.openList = false;
			}, 500);
		},
		input(event) {
			try {
				this.val = event ? event : this.val;
				//console.log('AutoComplete', 'input', this.val, event);
			
				//this.openList = true;
				if(this.openList || !this.value){
					this.search();
				}
				//this.$emit('input', this.val);
			} catch(e) {
				//console.warn('AutoComplete', 'input', e);
				this.emitInput(e);
			}
		},
		emitInput(val){
			if(val instanceof Error){
				if(this.value instanceof Error && this.value.message == val.message){
					//console.debug('AutoComplete', 'emitInput', val.message);
				} else {
					this.$emit('input', val)
				}
			} else if(this.val != this.value){
				this.$emit('input', val)
			}
		},
		clickAutoComplete(item, index){
			//console.log('AutoComplete', 'clickAutoComplete', item, index);
			this.openList = false;
			if(item !== undefined){
				this.val = this.itemText(item);
				this.$emit('autoComplete', item, index);
			} else if(this.listText){
				var temp = {};
				temp[this.listText] = this.val;
				this.$emit('autoComplete', temp, -1);
			} else {
				this.$emit('autoComplete', this.val, -1);
			}
			this.$emit('input', this.val);
		}, 
		search(){
			if(this.url){
				var param = Object.assign({}, this.param);
				param.searchText = this.value;
				this.$.httpPost(this.url, param).then((res) => {
					this.selectList = res.data.list;
				});
			}
		}
	},
	computed:{
		selectList(){
			var result = [];
			if(this.cdId && this.cdId == this.searchSrc){
				result = this.searchList;
			} else if(Array.isArray(this.list)){
				//list가 Array 일 경우
				result = this.list;
			} else if(typeof this.list == 'object'){
				//list가 Object일 경우
				for(var cd in this.list){
					switch(typeof this.list[cd]){
						case 'string':
						case 'number':
							var item1 = {};
							item1[this.codeKey] = cd;
							item1[this.nameKey] = this.list[cd];
							result.push(item1);
					}
				}
			} else if(typeof this.list == 'string'){
				//list가 string 일 경우
				var list = this.list.split(',');
				for(var i in list){
					var sp = list[i].split(':');
					if(sp.length >= 2){
						var item2 = {};
						item2[this.codeKey] = sp[0];
						item2[this.nameKey] = sp[1];
						result.push(item2);
					}
				}
			}
			//console.log('AutoComplete selectList', result);
			return result;
		},
		error(){
			if(this.val instanceof Error)
				return this.val;
			if(this.value instanceof Error)
				return this.value;
			else 
				return false;
		}
	}
}
</script>